import React, { Component } from 'react';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import '../../config';
//import Cookies from 'universal-cookie';
import axios from "axios";
//const cookies = new Cookies();
import queryString from 'query-string';
const qs = require('query-string');

class TopUp extends Component {

	constructor() {
		super();
		document.title = "Topup - Instant Credit";
		let today1 = new Date();
		this.state = {
			todayDate : today1,
			dateError:'',
			fullnameError: '',
			emailError: '',
			amountError: '',
					BLError:'',
					TLError:'',
					BRError:'',
					TRError:'',
			senderBankNameError: '',
			senderAcNameError: '',
			senderAcNumberError: '',
			receiverBankNameError: '',
			receiverAcNameError: '',
			receiverAcNumberError: '',
			purposeError: '',
			msg: '',
			fullName: '',
			email: '',
			amount: '',
			senderBankName: '',
			senderAccountName: '',
			senderAccountNumber: '',
			receiverBankName: '',
			receiverAccountName: '',
			receiverAccountNumber: '',
			//date: '',
			date: today1,
			purpose: '',
			severity: 'warning',
			severityDisplay: 'none',
			msgBlock: 'none',
			dummyData:'',
		};

	}
	handleTopUpSubmit = async (event) => {
		event.preventDefault();
		console.log(this.state.fullName);
		if('' == this.state.fullName)
		{
			window.scrollTo(0, 0);
			this.setState({
				fullnameError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				fullnameError:'',
			})
		}
		if('' == this.state.email)
		{
			window.scrollTo(0, 0);
			this.setState({
				emailError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				emailError:'',
			})
		}
		if('' == this.state.amount)
		{
			this.setState({
				amountError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				amountError:'',
			})
		}
		if('' == this.state.senderBankName)
		{
			this.setState({
				senderBankNameError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				senderBankNameError:'',
			})
		}
		if('' == this.state.senderAccountName)
		{
			this.setState({
				senderAcNameError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				senderAcNameError:'',
			})
		}
		if('' == this.state.senderAccountNumber)
		{
			this.setState({
				senderAcNumberError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				senderAcNumberError:'',
			})
		}
		if('' == this.state.receiverBankName)
		{
			this.setState({
				receiverBankNameError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				receiverBankNameError:'',
			})
		}
		if('' == this.state.receiverAccountName)
		{
			this.setState({
				receiverAcNameError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				receiverAcNameError:'',
			})
		}
		if('' == this.state.receiverAccountNumber)
		{
			this.setState({
				receiverAcNumberError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				receiverAcNumberError:'',
			})
		}
		if('' == this.state.date)
        {
            this.setState({
					dateError:'1.5px solid red',
					BLError:'10px',
					TLError:'10px',
					BRError:'4px',
					TRError:'4px',

            })
            return(false);
        }
        else
        {
            this.setState({
                dateError:'',
            })
        }
		if('' == this.state.purpose)
		{
			this.setState({
				purposeError:'1.5px solid red',
			})
			return(false);
		}
		else
		{
			this.setState({
				purposeError:'',
			})
		}
		let tDate = dateFormat(this.state.date, "yyyy-mm-dd");
		const config =
		{
			headers:
			{
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}
		const data =
		{
			va_id: this.props.vaId,
			token: this.props.userDetails._id,
			full_name: this.state.fullName,
			email: this.state.email,
			amount: this.state.amount,
			send_bank_name: this.state.senderBankName,
			send_account_name: this.state.senderAccountName,
			send_account_number: this.state.senderAccountNumber,
			recv_bank_name: this.state.receiverBankName,
			recv_account_name: this.state.receiverAccountName,
			recv_account_number: this.state.receiverAccountNumber,
			trans_date: tDate,
			purpose: this.state.purpose,
		}
		axios.post(global.config.main.baseUrl + 'do-topup', qs.stringify(data), config)
			.then(res => {
				if (res.data.status === 200) {
					this.toastSuccess(res.data.msg);
					this.setState({
						date : '',
					});
					let newDate = new Date();
					this.setState({
						date : dateFormat(newDate, "mm/dd/yyyy"),
					});
					console.log("DATE");
					console.log(this.state.date);
					window.location.reload();

					/*this.setState({
						fullName : '',
						email : '',
						amount : '',
						senderBankName : '',
						senderAccountName : '',
						senderAccountNumber : '',
						receiverBankName : '',
						receiverAccountName : '',
						receiverAccountNumber : '',
						purpose	: '',
					});
					this.setState({
						dummyData:"DUMMY VALUE"
					});*/
				}
				else {
					this.toastError(res.data.msg);
				}
			})
			.catch(error => {
				if (!error.response) {
					this.errormsg.show([
						{ severity: 'error', summary: 'Error', detail: "", sticky: true }
					]);
					this.setState({
						msgBlock: 'block',
						msg: "A network error occured",
					});
				}
			})

	}
	toastSuccess(msg) {
        this.toastBR.show({severity:'success', summary: '', detail:msg, life: 5000});
    }
	toastError(msg) {
        this.toastBR.show({severity:'error', summary: '', detail:msg, life: 5000});
    }

	render() {
		const cardStyle = { borderRadius: '40px', padding: '2em' };
		const submitButtonStyle = { backgroundColor: 'rgb(84,67,163)', fontWeight: '600', fontFamily: 'unset', fontSize: '16px', borderRadius: '10px', height: '40px' };
		const pgridCustomStyle = { marginRight: '0', marginLeft: '0' };
		const h1Style = { textAlign: 'center', fontWeight: '600', color: 'rgb(92, 74, 181)', textDecoration: 'underline' };
		const inputBoxStyle = {};//{backgroundColor:'rgb(240,240,240)',border:'0',borderRadius:'10px',padding:'10px',paddingLeft:'12px'};
		const labelStyle = { paddingLeft: '10px', fontSize: '12px', color: '#999', fontWeight: '500' }
		return (
			<div>
				<Toast ref={(el) => this.toastBR = el} position="top-right" />
				<form onSubmit={this.handleTopUpSubmit}>
					<div className="p-grid p-fluid" style={{ marginTop: '20px' }}>
						<div className="p-col-12 p-md-12 p-xl-12">
							<div className="p-grid p-fluid">
								<div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"  style={{ textAlign: 'center' }}>
									<span className="p-tag custom-heading">Top Up</span>
								</div>
							</div>
							<div className="p-grid p-fluid topUp" style={pgridCustomStyle}>
								<div className="p-col-12 p-sm-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1">
									<div className="card card-w-title" style={cardStyle}>
										<div className="p-grid">
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												<span className="p-float-label">
													<InputText value={this.state.fullName} onChange={(e) => this.setState({ fullName: e.target.value })} style={{border:this.state.fullnameError}} />
													<label htmlFor="inputtext" style={labelStyle}>Full Name</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
												<span className="p-float-label">
													<InputText type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}  style={{border:this.state.emailError}} />
													<label htmlFor="inputtext" style={labelStyle}>Email</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												<span className="p-float-label">
													<InputText type="number" value={this.state.amount} onChange={(e) => this.setState({ amount: e.target.value })}  style={{border:this.state.amountError}} />
													<label htmlFor="inputnumber" style={labelStyle}>Amount</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
												<span className="p-float-label">
													<InputText value={this.state.senderBankName} onChange={(e) => this.setState({ senderBankName: e.target.value })}  style={{border:this.state.senderBankNameError}} />
													<label htmlFor="inputtext" style={labelStyle}>Sender's Bank Name</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												<span className="p-float-label">
													<InputText id="inputtext" value={this.state.senderAccountName} onChange={(e) => this.setState({ senderAccountName: e.target.value })}  style={{border:this.state.senderAcNameError}} />
													<label htmlFor="inputtext" style={labelStyle}>Sender's Account Name</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
												<span className="p-float-label">
													<InputText type="number" value={this.state.senderAccountNumber} onChange={(e) => this.setState({ senderAccountNumber: e.target.value })}  style={{border:this.state.senderAcNumberError}} />
													<label htmlFor="inputtext" style={labelStyle}>Sender's Account Number</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												<span className="p-float-label">
													<InputText id="inputtext" value={this.state.receiverBankName} onChange={(e) => this.setState({ receiverBankName: e.target.value })}  style={{border:this.state.receiverBankNameError}} />
													<label htmlFor="inputtext" style={labelStyle}>Receiver's Bank Name</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
												<span className="p-float-label">
													<InputText id="inputtext" value={this.state.receiverAccountName} onChange={(e) => this.setState({ receiverAccountName: e.target.value })}  style={{border:this.state.receiverAcNameError}} />
													<label htmlFor="inputtext" style={labelStyle}>Receiver's Account Name</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5  p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												<span className="p-float-label">
													<InputText type="number" id="inputtext" value={this.state.receiverAccountNumber} onChange={(e) => this.setState({ receiverAccountNumber: e.target.value })}  style={{border:this.state.receiverAcNumberError}} />
													<label htmlFor="inputtext" style={labelStyle}>Receiver's Account Number</label>
												</span>
											</div>
											<div className="p-col-12 p-md-5 p-lg-5 p-xl-5 topUp">
												<span className="p-float-label">
													<Calendar id="calendar" value={this.state.date} onChange={(e) => this.setState({ date: e.value })} showIcon={true} style={{border:this.state.dateError,borderBottomLeftRadius:this.state.BLError,borderTopLeftRadius:this.state.TLError,borderBottomRightRadius:this.state.BRError,borderTopRightRadius:this.state.TRError}}/>
													<label htmlFor="calendar" style={labelStyle}>Date Performed</label>
												</span>
											</div>
											<div className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 topUp">
												{/*<span className="p-float-label">*/}
													<InputTextarea id="purpose" rows={3} cols={30} autoResize={true} value={this.state.purpose} onChange={(e) => this.setState({ purpose: e.target.value })}  style={{border:this.state.purposeError}} placeholder="Purpose"/>
													{/*<label htmlFor="purpose" style={labelStyle}>Purpose</label>
												</span>*/}
											</div>
										</div>
										<div className="p-grid topUp">
											<div className="p-col-12 p-md-4 p-lg-4 p-xl-4 p-md-offset-4 p-lg-offset-4 p-xl-offset-4">
												<Button type="submit" label="Top up" style={submitButtonStyle} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	console.log(state.login)
	return {
		userDetails: state.login.userDetails,
		vaId: state.login.vaData.vaid
	}
}

export default connect(mapStateToProps, null)(TopUp);

