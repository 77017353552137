import React, { Component } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";
import { Fieldset } from "primereact/fieldset";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import "../../config";
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
//const cookies = new Cookies();

const DEFAULT_PAGE_SIZE = 50;

class AdminVAHistory extends Component {
  constructor() {
    super();
    document.title = "Virtual Account History - Instant Credit";
    let today1 = new Date();
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      institutionName: "",
      instName: "",
      vaName: "",
      va_user_id: "",
      virtualAccountName: "",
      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      submitted: false,
      dropdownCity: "Ghana",
      dataTableDummyValue: "",
      today: today1,
      dropdowncountries: "Filter Filter",
      status: null,
      date2: null,
      previousFunction: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      picklistSourceCars: [],
      picklistTargetCars: [],
      orderlistCars: [],
      treeData1: [],
      treeData2: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
      statusFilter: [
        { label: "All", value: "ALL" },
        { label: "Top-up", value: "TOP_UP" },
        { label: "Adjustment", value: "ADJUSTMENT" },
        { label: "Transfer", value: "TRANSFER" },
        { label: "Reversal", value: "REVERSAL" },
      ],
      lazyParams: {
        first: 0,
        rows: DEFAULT_PAGE_SIZE,
        page: 1,
      },
      statusFilterValue: "ALL",
      totalCount: 10,
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;

    this.ConfirmDeleteProduct = this.ConfirmDeleteProduct.bind(this);
    this.HideTransactionDialog = this.HideTransactionDialog.bind(this);
    this.vaIDColumnTemplate = this.vaIDColumnTemplate.bind(this);
    this.amountColumnTemplate = this.amountColumnTemplate.bind(this);
    this.purposeColumnTemplate = this.purposeColumnTemplate.bind(this);
    this.newbalanceColumnTemplate = this.newbalanceColumnTemplate.bind(this);
    this.oldbalanceColumnTemplate = this.oldbalanceColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.changePage = this.changePage.bind(this);

    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }
  navigate2VA = (id) => {
    let payload = { userid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va");
      } else {
        alert("Failed");
      }
    });
  };

  vaIDColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">VA ID</span>
        {rowData.va_id}
      </React.Fragment>
    );
  }
  amountColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Amount</span>
        {rowData.amount}
      </React.Fragment>
    );
  }
  purposeColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Purpose</span>
        {rowData.purpose}
      </React.Fragment>
    );
  }
  newbalanceColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">New Balance</span>
        {rowData.new_balance}
      </React.Fragment>
    );
  }
  oldbalanceColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Old Balance</span>
        {rowData.old_balance}
      </React.Fragment>
    );
  }
  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date</span>
        {rowData.created_at}
      </React.Fragment>
    );
  }
  ConfirmDeleteProduct(product) {
    this.setState({
      transactionDialog: true,
    });
  }

  HideTransactionDialog() {
    this.setState({ transactionDialog: false });
  }

  componentDidMount() {
    this.UpdateDimensions();
    this.getFilteredData();
    window.addEventListener("resize", this.UpdateDimensions.bind(this));
  }

  pushVAID = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
      } else {
        alert("Failed");
      }
    });
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.UpdateDimensions.bind(this));
  }

  UpdateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  HideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };
  exportCSV() {
    this.dt.exportCSV();
  }
  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredData = async (page = 1) => {
    if (null != this.state.rangeValue[1]) {
      this.setState({ dialogVisible: false });
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      console.log("filtered value :: ", this.state.statusFilterValue);

      const data = {
        type: this.state.statusFilterValue,
        start_date: startDate,
        end_date: endDate,
        page: page,
        size: global.config.pagination.default_size,
        va_id: this.props.vaId,
        token: this.props.userDetails._id,
      };

      this.setState({
        previousFunction: this.getFilteredData,
      });

      if (this.state.statusFilterValue != "")
        data.type = this.state.statusFilterValue;

      axios
        .post(
          global.config.main.baseUrl + "list-topup",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.state.dataTableValue = [];
            this.CreateDataTable(res.data.data);
            this.setState(
              {
                instName: res.data.name,
                vaName: res.data.account_name,
                va_user_id: res.data.va_user_id,
              },
              () => this.setUserId(res.data.va_user_id, res.data.va_id)
            );

            this.setState({
              contentOpacity: 1,
              showLoader: "none",
              totalCount: res.data.total_count,
            });
            console.log("TOTAL_COUNT", res.data.total_count);
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  changePage(event) {
    console.log(event);
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams });

    const selectedPage = event.page;
    this.setState({
      previousFunction: selectedPage + 1,
    });
    this.getFilteredData(selectedPage + 1);
  }

  setUserId = (uId, vaId) => {
    let payload = { userid: uId, vaid: vaId };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
      } else {
      }
    });
  };
  // CreateDataTable(data) {
  //   this.state.dataTableValueNew = data;
  //   this.state.dataTableValueNew.forEach((d) => {
  //     this.state.dataTableValue.push({
  //       va_id: d["va_id"],
  //       amount: d["amount"],
  //       purpose: d["purpose"],
  //       new_balance: d["new_balance"],
  //       old_balance: d["old_balance"],
  //       created_at: d["created_at"],
  //     });
  //   });
  //   this.setState({ dataTableDummyValue: this.state.dataTableValue });
  // }

  CreateDataTable(data) {
    this.state.dataTableValueNew = data;

    const updateValue = [];
    this.state.dataTableValueNew.forEach((d) => {
      updateValue.push({
        va_id: d["va_id"],
        amount: d["amount"],
        purpose: d["purpose"],
        new_balance: d["new_balance"],
        old_balance: d["old_balance"],
        created_at: d["created_at"],
      });
    });

    this.setState({ dataTableValue: updateValue });
  }

  render() {
    const dialogFooter = (
      <Button
        label="Apply"
        icon="pi pi-search-plus"
        onClick={() => this.setState({ dialogValue: false })}
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );

    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };
    const transactionDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.HideTransactionDialog}
          style={{ marginTop: "1.5em" }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.HideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-12"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredData()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                />
                <label htmlFor="calendar">DATE RANGE</label>
              </span>
            </div>
          </div>
        </Dialog>
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  {/*<div className="card card-w-title">*/}
                  <div>
                    <span className="p-tag custom-heading">VA History</span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  {/*<Panel className="dashboard" header="Select Filters" toggleable={true}>*/}

                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-3">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredData()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label htmlFor="calendar">DATE RANGE</label>
                      </span>
                    </div>
                    <div className="p-field p-col-6 p-md-3">
                      <span className="p-float-label">
                        <Dropdown
                          style={filterInputBoxStyle}
                          id="dropdown1"
                          options={this.state.statusFilter}
                          value={this.state.statusFilterValue}
                          onChange={(event) => {
                            console.log("data :: ", event.value);
                            this.setState(
                              { statusFilterValue: event.value },
                              () => this.getFilteredData()
                            );
                          }}
                          autoWidth={false}
                        />
                        <label style={floatLabel}>OPERATION TYPE</label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  {/*<Fieldset legend={this.state.virtualAccountName} toggleable={false}>*/}
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <span
                        className="p-tag p-tag-info"
                        style={{
                          fontSize: "15px",
                          borderRadius: "8px",
                          marginBottom: "1em",
                        }}
                      >
                        <Link
                          to="/va"
                          onClick={() =>
                            this.navigate2VA(this.state.va_user_id)
                          }
                        >
                          <span style={{ fontWeight: "400", color: "#fff" }}>
                            {this.state.instName}
                          </span>
                          &nbsp;
                          <span style={{ color: "#fff" }}>
                            {this.state.vaName}
                          </span>
                        </Link>
                      </span>
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        totalRecords={this.state.totalCount}
                        value={this.state.dataTableValue}
                        ref={(el) => {
                          this.dt = el;
                        }}
                        paginator={true}
                        rows={DEFAULT_PAGE_SIZE}
                        lazy
                        first={this.state.lazyParams.first}
                        onPage={this.changePage}
                      >
                        <Column
                          field="va_id"
                          header="VA ID"
                          body={this.vaIDColumnTemplate}
                        />
                        <Column
                          field="amount"
                          header="Amount"
                          body={this.amountColumnTemplate}
                        />
                        <Column
                          field="purpose"
                          header="Purpose"
                          body={this.purposeColumnTemplate}
                        />
                        <Column
                          field="new_balance"
                          header="New Balance"
                          body={this.newbalanceColumnTemplate}
                        />
                        <Column
                          field="old_balance"
                          header="Old Balance"
                          body={this.oldbalanceColumnTemplate}
                        />
                        <Column
                          field="created_at"
                          header="Date"
                          body={this.dateColumnTemplate}
                        />
                      </DataTable>
                    </div>
                  </div>
                  {/*</Fieldset>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    vaId: state.login.vaData.vaid,
  };
};
const mapDispatchToProps = {
  vaAction: VAAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminVAHistory);
