import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Route, Redirect, useHistory } from "react-router-dom";
// import { Redirect } from 'react-router';
import PrivateRoute from "./PrivateRoute";

import Login from "./components/common/Login";
import ForgotPassword from "./components/common/ForgotPassword";
import Logout from "./components/common/Logout";

import Dashboard from "./components/institution/Dashboard";
import Admin from "./components/institution/Admin";
import Transactions from "./components/institution/Transactions";
import Report from "./components/institution/Report";
import VirtualAccounts from "./components/institution/VirtualAccounts";
import TopUp from "./components/institution/TopUp";
import TopUpHistory from "./components/institution/TopUpHistory";
import Profile from "./components/institution/Profile";

import AdminDashboard from "./components/admin/Dashboard";
import Countries from "./components/admin/Countries";
import Institution from "./components/admin/Institution";
import AddInstitutionReq from "./components/admin/InstitutionReqList";
import InstitutionList from "./components/admin/InstitutionList";
import AuditTrail from "./components/admin/AuditTrail";
import AdminForex from "./components/admin/Forex";
import ForexRequest from "./components/admin/ForexRequest";
import AdminForexRequests from "./components/admin/Requests";
import AdminVirtualAccounts from "./components/admin/VirtualAccounts";
import AdminVAHistory from "./components/admin/VAHistory";
import AdminProfile from "./components/admin/Profile";
import AdminReport from "./components/admin/Report";
import AdminAdjustments from "./components/admin/Adjustments";
import AdminTopUpRequest from "./components/admin/TopUpRequest";
import InstitutionTopUpRequest from "./components/admin/InstitutionTopUpRequest";
import AdminTransactions from "./components/admin/Transactions";
import Settings from "./components/admin/Settings";
import AdminSetting from "./components/admin/AdminSetting";
import AdminAdmin from "./components/admin/Admin";
import AdminUsers from "./components/admin/Users";
import AdminUsersList from "./components/admin/UsersList";
import AdminUsersReqList from "./components/admin/UsersReqList";
import AdminInstitutionUsers from "./components/admin/InstitutionUsers";
import AdminInstitutionUserReq from "./components/admin/InstitutionUserReq";
import DowntimeRequest from "./components/admin/DowntimeRequest";
import ProviderRequest from "./components/admin/ProviderRequest";
import ProviderSwitchRequest from "./components/admin/ProviderSwitchRequest";
import Provider from "./components/admin/Provider";
import ProviderApiRequest from "./components/admin/ProviderApiRequest";
import RemittancePartnerRequest from "./components/admin/RemittancePartnerRequest";

import FinanceDashboard from "./components/finance/Dashboard";
import FinanceTransactions from "./components/finance/Transactions";
import FinanceInstitutionList from "./components/finance/InstitutionList";
import FinanceVirtualAccounts from "./components/finance/VirtualAccounts";
import FinanceVAHistory from "./components/finance/VAHistory";
import FinanceVAAllHistory from "./components/finance/VAAllHistory";
import Forex from "./components/finance/Forex";
import FinanceAdminForexRequest from "./components/finance/ForexRequest";
import ForexRequestAdmin from "./components/finance/ForexRequestAdmin";
import ForexRequestUser from "./components/finance/ForexRequestUser";
//import FinanceReport from './components/finance/Report';
import FinanceReport from "./components/common/Report";
import Adjustments from "./components/finance/Adjustments";
import TopUpRequest from "./components/finance/TopUpRequest";
import AllRequests from "./components/finance/AllRequests";
import FinanceProfile from "./components/finance/Profile";
import FinanceInstitutionTopUpRequest from "./components/finance/InstitutionTopUpRequest";

import HelpDeskDashboard from "./components/helpdesk/Dashboard";
import HelpDeskTransactions from "./components/helpdesk/Transactions";
import AdminReportV2 from "./components/admin/AdminReportV2";
import InstitutionReportV2 from "./components/institution/InstitutionReportV2";
import HelpDeskForex from "./components/helpdesk/Forex";
//import HelpDeskReport from './components/helpdesk/Report';
import HelpDeskReport from "./components/common/Report";
import HelpDeskRequest from "./components/helpdesk/Request";
import HelpDeskAdminRequest from "./components/helpdesk/RequestHDA";
import HelpDeskVirtualAccounts from "./components/helpdesk/VirtualAccounts";
import HelpDeskVirtualAccountList from "./components/helpdesk/VirtualAccountList";
import VAHistory from "./components/helpdesk/VAHistory";
import HelpDeskProfile from "./components/helpdesk/Profile";

import PartnerConfiguration from "./components/partners/PartnerConfig";

import { IdleTimeoutManager } from "idle-timer-manager";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "./layout/layout.scss";
import "./App.scss";

class App extends Component {
  constructor() {
    super();
    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      manager: "",
      timeout: false,
    };
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  dashboardRoute() {
    if ("0" == this.props.userDetails.type) {
      return (
        <div>
          <PrivateRoute path="/dashboard" component={AdminDashboard} />
          <PrivateRoute path="/countries" component={Countries} />
          <PrivateRoute path="/institution" component={Institution} />
          <PrivateRoute
            path="/institution-request"
            component={AddInstitutionReq}
          />
          <PrivateRoute path="/forex" component={AdminForex} />
          <PrivateRoute path="/institution-list" component={InstitutionList} />
          <PrivateRoute path="/audit-trail" component={AuditTrail} />
          <PrivateRoute path="/forex-request" component={ForexRequest} />
          <PrivateRoute path="/requests" component={AdminForexRequests} />
          <PrivateRoute path="/va" component={AdminVirtualAccounts} />
          <PrivateRoute path="/va-history" component={AdminVAHistory} />
          <PrivateRoute path="/users" component={AdminUsers} />
          <PrivateRoute path="/profile" component={AdminProfile} />
          {/* <PrivateRoute path="/reports" component={AdminReport} /> */}
          <PrivateRoute path="/adjustments" component={AdminAdjustments} />
          <PrivateRoute path="/top-up-requests" component={AdminTopUpRequest} />
          <PrivateRoute
            path="/institution-top-up-request"
            component={InstitutionTopUpRequest}
          />
          <PrivateRoute path="/transactions" component={AdminTransactions} />
          <PrivateRoute path="/report" component={AdminReportV2} />
          <PrivateRoute path="/settings" component={AdminSetting} />
          <PrivateRoute path="/user-list" component={AdminUsersList} />
          <PrivateRoute path="/user-request" component={AdminUsersReqList} />
          <PrivateRoute
            path="/institution-users"
            component={AdminInstitutionUsers}
          />
          <PrivateRoute
            path="/institution-user-request"
            component={AdminInstitutionUserReq}
          />
          <PrivateRoute path="/downtime-request" component={DowntimeRequest} />
          <PrivateRoute path="/provider-request" component={ProviderRequest} />
          <PrivateRoute
            path="/provider-switch-request"
            component={ProviderSwitchRequest}
          />
          <PrivateRoute path="/provider" component={Provider} />
          <PrivateRoute
            path="/provider-api-request"
            component={ProviderApiRequest}
          />

          <PrivateRoute
            path="/partner-configuration"
            component={PartnerConfiguration}
          />
          <PrivateRoute
            path="/remittance-partner"
            component={PartnerConfiguration}
          />
          <PrivateRoute
            path="/remittance-partner-request"
            component={RemittancePartnerRequest}
          />
        </div>
      );
    } else if ("1" == this.props.userDetails.type) {
      return (
        <div>
          <PrivateRoute path="/dashboard" component={AdminDashboard} />
          <PrivateRoute path="/countries" component={Countries} />
          <PrivateRoute path="/institution" component={Institution} />
          <PrivateRoute
            path="/institution-request"
            component={AddInstitutionReq}
          />
          <PrivateRoute path="/forex" component={AdminForex} />
          <PrivateRoute path="/institution-list" component={InstitutionList} />
          <PrivateRoute path="/audit-trail" component={AuditTrail} />
          <PrivateRoute path="/forex-request" component={ForexRequest} />
          <PrivateRoute path="/requests" component={AdminForexRequests} />
          <PrivateRoute path="/va" component={AdminVirtualAccounts} />
          <PrivateRoute path="/va-history" component={AdminVAHistory} />
          {/*<PrivateRoute path="/users" component={AdminAdmin} />*/}
          <PrivateRoute path="/users" component={AdminUsersList} />
          <PrivateRoute path="/profile" component={AdminProfile} />
          {/* <PrivateRoute path="/reports" component={AdminReport} /> */}
          <PrivateRoute path="/report" component={AdminReportV2} />
          <PrivateRoute path="/adjustments" component={AdminAdjustments} />
          <PrivateRoute path="/top-up-requests" component={AdminTopUpRequest} />
          <PrivateRoute path="/transactions" component={AdminTransactions} />
          <PrivateRoute
            path="/institution-users"
            component={AdminInstitutionUsers}
          />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute
            path="/institution-user-request"
            component={AdminInstitutionUserReq}
          />
          <PrivateRoute path="/provider" component={Provider} />

          <PrivateRoute
            path="/partner-configuration"
            component={PartnerConfiguration}
          />
          <PrivateRoute
            path="/remittance-partner"
            component={PartnerConfiguration}
          />
        </div>
      );
    } else if ("101" == this.props.userDetails.type) {
      //Finance Admin
      return (
        <div>
          <PrivateRoute path="/" exact component={FinanceDashboard} />
          <PrivateRoute path="/dashboard" component={FinanceDashboard} />
          <PrivateRoute path="/transactions" component={FinanceTransactions} />
          <PrivateRoute path="/va" component={FinanceVirtualAccounts} />
          <PrivateRoute path="/forex" component={Forex} />
          {/* <PrivateRoute path="/reports" component={FinanceReport} /> */}
          <PrivateRoute path="/report" component={AdminReportV2} />
          <PrivateRoute path="/forex-request" component={ForexRequestAdmin} />
          <PrivateRoute
            path="/institutions"
            component={FinanceInstitutionList}
          />
          <PrivateRoute path="/va-history" component={FinanceVAHistory} />
          <PrivateRoute
            path="/va-all-history"
            component={FinanceVAAllHistory}
          />
          <PrivateRoute path="/profile" component={FinanceProfile} />
          <PrivateRoute path="/adjustments" component={Adjustments} />
          <PrivateRoute path="/top-up-requests" component={TopUpRequest} />
          <PrivateRoute path="/requests" component={AllRequests} />
          <PrivateRoute
            path="/institution-topup-request"
            component={FinanceInstitutionTopUpRequest}
          />
        </div>
      );
    } else if ("102" == this.props.userDetails.type) {
      //Finance User
      return (
        <div>
          <PrivateRoute path="/" exact component={FinanceDashboard} />
          <PrivateRoute path="/dashboard" component={FinanceDashboard} />
          <PrivateRoute path="/transactions" component={FinanceTransactions} />
          <PrivateRoute path="/va" component={FinanceVirtualAccounts} />
          <PrivateRoute path="/forex" component={Forex} />
          <PrivateRoute path="/report" component={AdminReportV2} />
          <PrivateRoute
            path="/institutions"
            component={FinanceInstitutionList}
          />
          <PrivateRoute path="/va-history" component={FinanceVAHistory} />
          <PrivateRoute path="/forex-request" component={ForexRequestAdmin} />
          {/*<PrivateRoute path="/forex-request" component={ForexRequestUser} />*/}
          <PrivateRoute path="/profile" component={FinanceProfile} />
          <PrivateRoute path="/requests" component={AllRequests} />
          <PrivateRoute path="/adjustments" component={Adjustments} />
          <PrivateRoute path="/top-up-requests" component={TopUpRequest} />
          <PrivateRoute
            path="/institution-topup-request"
            component={FinanceInstitutionTopUpRequest}
          />
          {/*<PrivateRoute path="/forex-request" component={FinanceAdminForexRequest} />*/}
          {/*<PrivateRoute path="/forex-request" component={Request} />
                    <PrivateRoute path="/request" component={FinanceAdminForexRequest} />*/}
        </div>
      );
    } else if ("201" == this.props.userDetails.type) {
      //Help Desk Admin
      return (
        <div>
          <PrivateRoute path="/" exact component={HelpDeskDashboard} />
          <PrivateRoute path="/dashboard" component={HelpDeskDashboard} />
          <PrivateRoute path="/transactions" component={HelpDeskTransactions} />
          <PrivateRoute path="/forex" component={HelpDeskForex} />
          {/* <PrivateRoute path="/reports" component={HelpDeskReport} /> */}
          <PrivateRoute path="/report" component={AdminReportV2} />

          <PrivateRoute
            path="/institutions"
            component={HelpDeskVirtualAccountList}
          />
          <PrivateRoute path="/va" component={HelpDeskVirtualAccounts} />
          <PrivateRoute path="/request" component={HelpDeskAdminRequest} />
          {/*<PrivateRoute path="/requests" component={HelpDeskAdminRequest} />*/}
          <PrivateRoute path="/profile" component={HelpDeskProfile} />
          <PrivateRoute path="/va-history" component={VAHistory} />
        </div>
      );
    } else if ("202" == this.props.userDetails.type) {
      //Help Desk User
      return (
        <div>
          <PrivateRoute path="/" exact component={HelpDeskDashboard} />
          <PrivateRoute path="/dashboard" component={HelpDeskDashboard} />
          <PrivateRoute path="/transactions" component={HelpDeskTransactions} />
          <PrivateRoute path="/forex" component={HelpDeskForex} />
          {/* <PrivateRoute path="/reports" component={HelpDeskReport} /> */}
          <PrivateRoute path="/report" component={AdminReportV2} />
          <PrivateRoute path="/request" component={HelpDeskRequest} />
          <PrivateRoute path="/va" component={HelpDeskVirtualAccounts} />
          <PrivateRoute
            path="/institutions"
            component={HelpDeskVirtualAccountList}
          />
          <PrivateRoute path="/profile" component={HelpDeskProfile} />
          <PrivateRoute path="/va-history" component={VAHistory} />
        </div>
      );
    } else if ("302" == this.props.userDetails.type) {
      return (
        <div>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/users" component={Admin} />
          <PrivateRoute path="/va" component={VirtualAccounts} />
          {/* <PrivateRoute path="/reports" component={Report} /> */}
          <PrivateRoute path="/report" component={InstitutionReportV2} />
          <PrivateRoute path="/top-up" component={TopUp} />
          <PrivateRoute path="/top-up-history" component={TopUpHistory} />
          <PrivateRoute path="/profile" component={Profile} />
        </div>
      );
    } else if (
      "301" == this.props.userDetails.type ||
      "303" == this.props.userDetails.type
    ) {
      return (
        <div>
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/users" component={Admin} />
          <PrivateRoute path="/va" component={VirtualAccounts} />
          <PrivateRoute path="/report" component={InstitutionReportV2} />
          <PrivateRoute path="/top-up" component={TopUp} />
          <PrivateRoute path="/top-up-history" component={TopUpHistory} />
          <PrivateRoute path="/profile" component={Profile} />
        </div>
      );
    } else {
      return (
        <div>
          <Redirect to="/login" />
        </div>
      );
    }
  }
  render() {
    return (
      <div>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/logout" component={Logout} />
        {this.dashboardRoute()}
      </div>
      // {/* <AppFooter /> */}
      //     <div className="layout-mask"></div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps)(App);
