import React, { Component } from "react";
import copy from "copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { connect } from "react-redux";
import CountryFlag from "react-country-flag";
import "../../config";
import axios from "axios";

const qs = require("query-string");
const confirmationLogo = "assets/layout/images/confirmation.png";
const DEFAULT_PAGE_SIZE = 50;

class AdminTransactions extends Component {
  constructor() {
    super();
    document.title = "Transactions - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      searchFilterOld: [
        { label: "Short Name", value: "short_name" },
        { label: "Transaction Id", value: "trans_id" },
      ],
      searchFilter: [
        { label: "Destination", value: "phone" },
        { label: "Transaction Id", value: "trans_id" },
        { label: "Institution Id", value: "inst_id" },
      ],
      lazyParams: {
        first: 0,
        rows: DEFAULT_PAGE_SIZE,
        page: 1,
      },
      copyToolTipText: "Copy",
      totalRecords: 0,
      rangeValue: [],
      previousFunction: null,
      actionIcon: "",
      dialogPhone: "",
      dialogTransId: "",
      dialogRefId: "",
      dateChange: "N",
      dialogAmount: "",
      dialogCharge: "",
      dialogCountry: "",
      dialogAccountNum: "",
      dialogShortName: "",
      dialogName: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogBalanceBefore: "",
      dialogBalanceAfter: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      transactionDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      dropdowncountries: "Filter Filter",
      status: null,
      date2: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      cities: [],
      dialoginstitutionId: "",
      showLoader: "none",
      contentOpacity: "1",
      copyTransID: "",
      dummyValue: "",
      mobileView: "",
      webView: "",
      transType: "",
      showAccountNumber: "",
      showDestinationHouse: "",
      showBankName: "",
      bankName: "",
      bankShortName: "",
      showPhoneNumber: "",
      dialogSenderCurrency: "",
      retryTransactionPromptMessage: "Action will send propmt to Admin",
      statusFilter: [
        { label: "All", value: "All" },
        { label: "Pending", value: "0" },
        { label: "Success", value: "1" },
        { label: "Failed", value: "2" },
        { label: "Processing", value: "4" },
        { label: "Low_Bal", value: "7" },
        { label: "Re_Queued", value: "8" },
        { label: "Cancelled", value: "9" },
      ],
      statusFilterValue: "All",
    };

    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = today;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.viewTransactionDetails = this.viewTransactionDetails.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.transIDColumnTemplate = this.transIDColumnTemplate.bind(this);
    this.institutionIDColumnTemplate =
      this.institutionIDColumnTemplate.bind(this);
    this.amountColumnTemplate = this.amountColumnTemplate.bind(this);
    this.chargeColumnTemplate = this.chargeColumnTemplate.bind(this);
    this.phoneColumnTemplate = this.phoneColumnTemplate.bind(this);
    this.countryColumnTemplate = this.countryColumnTemplate.bind(this);
    this.detailsColumnTemplate = this.detailsColumnTemplate.bind(this);
    this.statusColumnTemplate = this.statusColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.phoneColumnTemplate = this.phoneColumnTemplate.bind(this);
    this.toastSuccess = this.toastSuccess.bind(this);
    this.openNew = this.openNew.bind(this);
    this.shortNameColumnTemplate = this.shortNameColumnTemplate.bind(this);
    this.institutionColumnTemplate = this.institutionColumnTemplate.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  shortNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Short Name</span>
        {rowData.short_name}
      </React.Fragment>
    );
  }
  institutionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">User ID</span>
        {rowData.institution_id}
      </React.Fragment>
    );
  }
  clickMenu = () => {
    copy(this.state.copyTransID);

    this.state.copyToolTipText = "Copied";
    this.setState({
      copyToolTipText: "Copied",
    });

    this.setState({ dummyValue: "Copied" });
    this.state.dummyValue = "Copied";
  };
  onMouseLeaveHandler = () => {
    this.state.copyToolTipText = "Copy";
    this.setState({
      copyToolTipText: "Copy",
    });

    this.setState({ dummyValue: "Copy" });
    this.state.dummyValue = "Copy";
  };
  transIDColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Trans ID</span>
        <div style={{ display: this.state.mobileView }}>
          <span>
            <label className="show_copy">
              {rowData.transId}
              <label
                className="edit_hover_class"
                tooltip={this.state.copyToolTipText}
                onMouseOut={this.onMouseLeaveHandler}
                tooltipOptions={{ position: "right" }}
              >
                <a
                  onClick={() =>
                    this.setState({ copyTransID: rowData.transId }, () =>
                      this.clickMenu()
                    )
                  }
                >
                  <div
                    className="tooltipClipBoard"
                    onMouseOut={this.onMouseLeaveHandler}
                  >
                    <span className="tooltiptext">
                      {this.state.copyToolTipText}
                    </span>
                    <i className="pi pi-copy"></i>
                  </div>
                </a>
              </label>
            </label>
          </span>
        </div>
        <div style={{ display: this.state.webView }}>
          <span>
            <label className="show_copy">
              {rowData.transId.substring(0, 9)}
              <label
                className="edit_hover_class"
                tooltip={this.state.copyToolTipText}
                onMouseOut={this.onMouseLeaveHandler}
                tooltipOptions={{ position: "right" }}
              >
                <a
                  onClick={() =>
                    this.setState({ copyTransID: rowData.transId }, () =>
                      this.clickMenu()
                    )
                  }
                >
                  <div
                    className="tooltipClipBoard"
                    onMouseOut={this.onMouseLeaveHandler}
                  >
                    <span className="tooltiptext">
                      {this.state.copyToolTipText}
                    </span>
                    &nbsp;&nbsp;
                    <i className="pi pi-copy"></i>
                  </div>
                </a>
              </label>
            </label>
          </span>
        </div>
      </React.Fragment>
    );
  }
  institutionIDColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution ID</span>
        {rowData.va_id}
      </React.Fragment>
    );
  }
  detailsColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution ID</span>
        {rowData.trans_desc}
      </React.Fragment>
    );
  }

  amountColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Amount</span>
        {rowData.amount}
      </React.Fragment>
    );
  }

  chargeColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Charge</span>
        {rowData.chargeAmount}
      </React.Fragment>
    );
  }

  phoneColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Destination</span>
        <span style={{ wordBreak: "break-all" }}>{rowData.phone}</span>
      </React.Fragment>
    );
  }

  countryColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Country</span>
        <span>
          <CountryFlag
            countryCode={rowData.country}
            svg
            style={{ width: "25px", height: "15px" }}
          />
          &nbsp; {rowData.country}
        </span>
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date / Time</span>
        {rowData.date}
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "";
    if (ptag == "Processing") {
      status = "PROCESSING";
      pTag = "p-tag p-tag-pending";
    } else if (ptag == "Re_Queued") {
      status = "RE_QUEUED";
      pTag = "p-tag p-tag-pending";
    } else if (ptag == "Low_Bal") {
      status = "LOW_BAL";
      pTag = "p-tag p-tag-pending";
    } else if (ptag == "Pending") {
      status = "PENDING";
      pTag = "p-tag p-tag-warning";
    } else if (ptag == "Success") {
      status = "SUCCESS";
      pTag = "p-tag p-tag-success";
    } else if (ptag == "Failed") {
      status = "FAILED";
      pTag = "p-tag p-tag-danger";
    } else {
      status = "CANCELLED";
      pTag = "p-tag";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon={this.state.actionIcon}
          className="p-button-success p-mr-2"
          onClick={() => this.viewTransactionDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip="View Transaction Details"
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }

  requestRetryTransaction = (event) => {
    event.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      trans_id: this.state.dialogTransId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "request-retry",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideConfirmationDialog();
          this.toastSuccess(res.data.msg);
          this.getTransactionData();
        } else {
          this.hideConfirmationDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  openConfirmationDialog = () => {
    this.setState({
      confirmationDialog: true,
      transactionDialog: false,
    });
  };
  hideConfirmationDialog = () => {
    this.setState({ confirmationDialog: false });
  };

  viewTransactionDetails(rowData) {
    console.log(rowData);
    if (1 == rowData.send_mode) {
      this.setState({
        transType: "WALLET",
        showAccountNumber: "none",
        showDestinationHouse: "none",
        showBankName: "none",
        showPhoneNumber: "flex",
      });
    } else {
      this.setState({
        transType: "BANK",
        showAccountNumber: "flex",
        showDestinationHouse: "none",
        showBankName: "flex",
        bankName: rowData.recipient_bank_name,
        bankShortName: rowData.recipient_bank_short_name,
        showPhoneNumber: "none",
      });
    }
    if ("Pending" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "PENDING",
        retryTransactionButton: "none",
        reversalTransactionButton: "none",
        closeTransactionButton: "initial",
      });
    } else if ("Success" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-success",
        dialogStatusText: "SUCCESS",
        retryTransactionButton: "none",
        reversalTransactionButton: "initial",
        closeTransactionButton: "none",
      });
    } else if ("Processing" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "PROCESSING",
        retryTransactionButton: "none",
        reversalTransactionButton: "initial",
        closeTransactionButton: "none",
      });
    } else if ("Re_Queued" == rowData.status || "Low_Bal" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-pending",
        dialogStatusText: "RE_QUEUED",
        retryTransactionButton: "none",
        reversalTransactionButton: "none",
        closeTransactionButton: "none",
      });
    } else if ("Failed" == rowData.status) {
      this.setState({
        dialogStatus: "product-badge status-failed",
        dialogStatusText: "FAILED",
        retryTransactionButton: "initial",
        reversalTransactionButton: "initial",
        closeTransactionButton: "none",
      });
    } else {
      this.setState({
        dialogStatus: "product-badge",
        dialogStatusText: "CANCELLED",
        retryTransactionButton: "initial",
        reversalTransactionButton: "none",
        closeTransactionButton: "none",
      });
    }

    this.setState({
      dialogRefId: rowData.ref_id,
      dialogTransId: rowData.transId,
      dialogAmount: rowData.amount,
      dialogCharge: rowData.chargeAmount,
      dialogCountry: rowData.country,
      dialogAccountNum: rowData.account_number,
      dialogName: rowData.name,
      dialogPhone: rowData.phone,
      dialoginstitutionId: rowData.institution_id,
      dialogDestinationHouse: rowData.destination_house,
      dialogExchangeRate: rowData.exchange_rate,
      dialogReciAccountNumber: rowData.recipient_account_no,
      dialogReciCurrency: rowData.recipient_currency,
      dialogSenderCurrency: rowData.sender_currency,
      dialogTransDesc: rowData.trans_desc,
      dialogBalanceBefore: rowData.balance_before,
      dialogBalanceAfter: rowData.balance_after,
      dialogVaId: rowData.va_id,
      dialogShortName: rowData.short_name,

      transactionDialog: true,
    });
  }

  hideTransactionDialog() {
    this.setState({ transactionDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    console.log("TYPE");
    console.log(this.props.userDetails.type);
    if ("0" == this.props.userDetails.type) {
      this.setState({
        retryTransactionPromptMessage: "This will initiate a Retry Transaction",
      });
    } else {
      this.setState({
        retryTransactionPromptMessage: "Action will send propmt to Admin",
      });
    }
    if ("202" == this.props.userDetails.type) {
      this.setState({
        actionIcon: "pi pi-pencil",
      });
    } else {
      this.setState({
        actionIcon: "pi pi-eye",
      });
    }
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
    this.updateDimensions();
    this.getTransactionData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 500) {
      this.setState({
        mobileView: "block",
        webView: "none",
      });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({
        mobileView: "none",
        webView: "block",
      });
    }
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    {
      /*this.dt.exportCSV();*/
    }
    this.dy.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataCountry = async () => {
    this.state.dialogVisible = false;
    let country = this.state.dropdownCity;
    let ifDateChange = this.state.dateChange;
    this.filterDataByDateCountry();
  };

  getFilterDataTC = async (page = 1) => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      country: this.state.dropdownCity,
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };
    this.setState({
      previousFunction: this.getFilterDataTC,
    });

    axios
      .post(
        global.config.main.baseUrl + "list-trans",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getFilterDataTD = async (page = 1) => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };
    this.setState({
      previousFunction: this.getFilterDataTD,
    });
    axios
      .post(
        global.config.main.baseUrl + "list-trans",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getFilteredData = async () => {
    console.log("About to set filter details..");
    if (null != this.state.rangeValue[1]) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      this.getTransactionData();
      {
        /*let country = this.state.dropdownCity;
				if (country == 'All') {
        	    this.setState({
        	        filterCountry: this.country
        	    }, () => this.getTransactionData());
        	}
        	else {
        	    this.setState({
        	        filterCountry: this.country
        	    }, () => this.getTransactionDataDateCountry());
        	}*/
      }
    }
  };

  getFilteredDataStatus = async (page = 1) => {
    console.log(this.state.statusFilterValue);
    if (null != this.state.rangeValue[1]) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        status: this.state.statusFilterValue,
        token: this.props.userDetails._id,
        page: page,
        size: DEFAULT_PAGE_SIZE,
      };
      this.setState({
        previousFunction: this.getFilteredDataStatus,
      });
      axios
        .post(
          global.config.main.baseUrl + "list-trans",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.state.dialogVisible = false;
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
              totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
            });
          } else {
            this.state.dialogVisible = false;
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };
  filterDataByDateCountry = async (page = 1) => {
    if (null != this.state.rangeValue[1]) {
      window.scrollTo(0, 1);
      window.scrollTo(0, 0);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        country: this.state.dropdownCity,
        token: this.props.userDetails._id,
        page: page,
        size: DEFAULT_PAGE_SIZE,
      };
      this.setState({
        previousFunction: this.filterDataByDateCountry,
      });
      axios
        .post(
          global.config.main.baseUrl + "list-trans",
          qs.stringify(data),
          config
        )
        .then((res) => {
          if (res.data.status === 200) {
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
              totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
            });
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  filterDataByDate = async (page = 1) => {
    console.log("About to execute .. ");
    if (null != this.state.rangeValue[1]) {
      window.scrollTo(0, 10);
      window.scrollTo(0, 0);
      console.log("Before startDate and endDate");
      let startDate = this.convertDate(this.state.rangeValue[0]);
      let endDate = this.convertDate(this.state.rangeValue[1]);
      this.setState({
        contentOpacity: 0.5,
        showLoader: "block",
      });
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const data = {
        start_date: startDate,
        end_date: endDate,
        token: this.props.userDetails._id,
        page: page,
        size: DEFAULT_PAGE_SIZE,
      };
      this.setState({
        previousFunction: this.filterDataByDate,
      });

      console.log("Before the filter request ..");
      axios
        .post(
          global.config.main.baseUrl + "list-trans",
          qs.stringify(data),
          config
        )
        .then((res) => {
          console.log("Response received for filter.. ", res);
          if (res.data.status === 200) {
            this.createDataTable(res.data.data);
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
              totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
            });
          } else {
            this.setState({
              contentOpacity: 1,
              showLoader: "none",
            });
          }
        })
        .catch((error) => {
          console.error("An error occured :: ", error);
          if (!error.response) {
            this.setState({
              msg: "A network error occurred",
            });
          }
        });
    }
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    else this.state.dialogVisible = false;
    this.state.dialogVisible = false;
    this.getTransactionDataSearchValue();
  };

  getTransactionDataSearchValue = async (page = 1) => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };
    this.setState({
      previousFunction: this.getTransactionDataSearchValue,
    });
    axios
      .post(
        global.config.main.baseUrl + "list-trans",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getTransactionDataDateCountry = async (page = 1) => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    console.log(this.state.dropdownCity);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      country: this.state.dropdownCity,
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };
    this.setState({
      previousFunction: this.getTransactionDataDateCountry,
    });

    axios
      .post(
        global.config.main.baseUrl + "list-trans",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getTransactionData = async (page = 1) => {
    console.log("Getting transaction details.. ");
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);

    console.log("Done converting the date..");
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
      page: page,
      size: DEFAULT_PAGE_SIZE,
    };
    this.setState({
      previousFunction: this.getTransactionData,
    });

    console.log("About to execute axios call..");
    axios
      .post(
        global.config.main.baseUrl + "list-trans",
        qs.stringify(data),
        config
      )
      .then((res) => {
        console.log("response received for filtering data..", res);
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            totalRecords: res.data.total || DEFAULT_PAGE_SIZE,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  changePage(event) {
    console.log(event);
    let lazyParams = { ...this.state.lazyParams, ...event };
    this.setState({ lazyParams });

    const selectedPage = event.page;
    console.log("testing this ::", selectedPage);
    this.setState({
      previousFunction: selectedPage + 1,
    });
    this.getTransactionData(selectedPage + 1);
    console.log("reached :: ", this.state.previousFunction);
  }

  createDataTable(data) {
    let destination = "";
    let type = "";
    let destination_channel = "";
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      if ("0" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          destination_channel = d["channel"];
          type = "Wallet";
        } else {
          destination = d["recipient_account_no"];
          destination_channel = d["recipient_bank_short_name"];
          type = "Bank";
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Pending",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else if ("1" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Success",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else if ("2" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Failed",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else if ("3" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Cancelled",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else if ("7" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Low_Bal",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else if ("8" == d["status"] || "9" == d["status"]) {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Re_Queued",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      } else {
        let full_amount = d["exchange_rate"] * d["trans_amount"];
        if (1 == d["send_mode"]) {
          destination = d["phone"];
          type = "Wallet";
          destination_channel = d["channel"];
        } else {
          destination = d["recipient_account_no"];
          type = "Bank";
          destination_channel = d["recipient_bank_short_name"];
        }
        this.state.dataTableValue.push({
          transId: d["trans_id"],
          amount: d["trans_amount"],
          chargeAmount: d["charges"],
          phone: destination,
          country: d["recipient_country"],
          date: d["created_at"],
          status: "Processing",
          name: d["recipient_name"],
          account_number: d["recipient_account_no"],
          destination_house: d["destination_house"],
          exchange_rate: d["exchange_rate"],
          recipient_account_no: d["recipient_account_no"],
          recipient_currency: d["recipient_currency"],
          trans_desc: d["trans_desc"],
          balance_before: d["balance_before"],
          balance_after: d["balance_after"],
          va_id: d["va_id"],
          institution_id: d["user_id"]["user_id"],
          short_name: d["user_id"]["short_name"],
          recipient_bank_name: d["recipient_bank_name"],
          send_mode: d["send_mode"],
          recipient_bank_short_name: d["recipient_bank_short_name"],
          sender_currency: d["sender_currency"],
          type: type,
          ref_id: d["ref_id"],
          charge: 0,
          src_result_code: 0,
          sender_name: d["sender_name"],
          recipient_address: "",
          dest_result_code: d["result_code"],
          sender_desc: d["sender_desc"],
          destination_channel: destination_channel,
          full_amount: full_amount,
        });
      }
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const dialogFooter = (
      <Button
        label="Apply"
        onClick={() => this.getSearchedData()}
        icon="pi pi-search-plus"
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const transactionDialogFooter = (
      <React.Fragment>
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.openConfirmationDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px", width: "100%" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "right",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  {/*<div className="card card-w-title">*/}
                  <div>
                    <span className="p-tag custom-heading">Transactions</span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                    {/* This is the export part */}
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-download"
                        onClick={this.exportCSV}
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "right",
                        }}
                        data-tip="Download Transaction List"
                        data-for="toolTip1"
                        data-place="top"
                      />
                      <ReactTooltip id="toolTip1" />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-3">
                      <span className="p-float-label">
                        {/*<Calendar inputStyle={filterInputBoxStyle} id="calendar" value={this.state.today} onChange={(e) => this.setState({ rangeValue: e.value, dateChange: 'Y' }, () => this.getFilteredData())} showIcon={true} />*/}
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredData()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label style={floatLabel}>DATE RANGE </label>
                      </span>
                    </div>

                    <div className="p-field p-col-6 p-md-2">
                      <span className="p-float-label">
                        <Dropdown
                          style={filterInputBoxStyle}
                          id="dropdown1"
                          options={this.state.cities}
                          value={this.state.dropdownCity}
                          onChange={(event) =>
                            this.setState({ dropdownCity: event.value }, () =>
                              this.getFilteredDataCountry()
                            )
                          }
                          autoWidth={false}
                        />
                        <label style={floatLabel}>COUNTRY</label>
                      </span>
                    </div>

                    <div className="p-col-4 p-md-3 p-xl-2">
                      <span className="p-float-label">
                        <Dropdown
                          style={filterInputBoxStyle}
                          id="dropdown1"
                          options={this.state.statusFilter}
                          value={this.state.statusFilterValue}
                          onChange={(event) =>
                            this.setState(
                              { statusFilterValue: event.value },
                              () => this.getFilteredDataStatus()
                            )
                          }
                          autoWidth={false}
                        />
                        <label style={floatLabel}>STATUS</label>
                      </span>
                    </div>
                    <div className="p-col-12 p-md-4 p-xl-4">
                      <div className="p-grid">
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingRight: "2px" }}
                        >
                          <div className="p-inputgroup">
                            <span className="p-float-label">
                              <Dropdown
                                style={{ width: "20%" }}
                                options={this.state.searchFilter}
                                value={this.state.dropdownSearchFilter}
                                onChange={(event) =>
                                  this.setState({
                                    dropdownSearchFilter: event.value,
                                  })
                                }
                                autoWidth={false}
                              />
                              <label style={floatLabel}>Select Filter</label>
                            </span>
                          </div>
                        </div>
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingLeft: "0.4px" }}
                        >
                          <div className="p-inputgroup">
                            <InputText
                              value={this.state.searchValue}
                              onChange={this.handleSearchValueChange}
                              placeholder="Search Value"
                            />
                            <Button
                              onClick={() => this.getSearchedData()}
                              icon="pi pi-search"
                              className="p-button-info"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        value={this.state.dataTableValue}
                        ref={(el) => {
                          this.dy = el;
                        }}
                        style={{ display: "none" }}
                      >
                        <Column field="transId" header="Id" />
                        <Column field="type" header="Type" />
                        <Column field="date" header="Date / Time" />
                        <Column field="short_name" header="Source" />
                        <Column field="transId" header="Source refID" />
                        <Column field="full_amount" header="Amount" />
                        <Column field="charges" header="Charge" />
                        <Column field="institution_id" header="Source userID" />
                        <Column
                          field="src_result_code"
                          header="Source Result Code"
                        />
                        <Column
                          field="sender_desc"
                          header="Source Result Msg"
                        />
                        <Column field="country" header="Source Country" />
                        <Column field="sender_name" header="Sender Name" />
                        <Column
                          field="recipient_currency"
                          header="Destination Currency"
                        />
                        <Column field="name" header="Recipient Name" />
                        <Column field="amount" header="Source Amount" />
                        <Column
                          field="sender_currency"
                          header="Source Currency"
                        />
                        <Column
                          field="recipient_address"
                          header="Receipient Address"
                        />
                        <Column field="phone" header="Recipient Mobile No" />
                        <Column
                          field="destination_channel"
                          header="Destination"
                        />
                        <Column field="ref_id" header="Destination refID" />
                        <Column
                          field="phone"
                          header="Destination (wallet/account)"
                        />
                        <Column
                          field="dest_result_code"
                          header="Destination Result Code"
                        />
                        <Column
                          field="trans_desc"
                          header="Destination Result Msg"
                        />
                        <Column field="status" header="Status" />
                      </DataTable>
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        totalRecords={this.state.totalRecords}
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={DEFAULT_PAGE_SIZE}
                        ref={(el) => {
                          this.dt = el;
                        }}
                        lazy
                        first={this.state.lazyParams.first}
                        onPage={this.changePage}
                      >
                        {/*<Column field="destination_house" style={{ display: 'none' }} />
                                                <Column field="exchange_rate" style={{ display: 'none' }} />
                                                <Column field="recipient_account_no" style={{ display: 'none' }} />
                                                <Column field="recipient_bank_short_name" style={{ display: 'none' }} />
                                                <Column field="institution_id" header="Institution ID" body={this.institutionColumnTemplate} style={{ display: 'none' }} />
                                                <Column field="trans_desc" header="Details" body={this.detailsColumnTemplate} style={{ display: 'none' }} />
                                                <Column field="va_id" style={{ display: 'none' }} />*/}
                        <Column
                          field="transId"
                          header="Id"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="type"
                          header="Type"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="short_name"
                          header="Source"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="ref_id"
                          header="Source refID"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="charge"
                          header="Charge"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="institution_id"
                          header="Source userID"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="src_result_code"
                          header="Source Result Code"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="sender_desc"
                          header="Source Result Msg"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="country"
                          header="Source Country"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="sender_name"
                          header="Sender Name"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="recipient_currency"
                          header="Destination Currency"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="recipient_name"
                          header="Recipient Name"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="amount"
                          header="Source Amount"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="sender_currency"
                          header="Source Currency"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="recipient_address"
                          header="Receipient Address"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="destination"
                          header="Recipient Mobile No"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="destination_channel"
                          header="Destination"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="ref_id"
                          header="Destination refID"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="destination"
                          header="Destination (wallet/account)"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="dest_result_code"
                          header="Destination Result Code"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="trans_desc"
                          header="Destination Result Msg"
                          style={{ display: "none" }}
                        />

                        <Column
                          field="transId"
                          header="Transaction ID"
                          body={this.transIDColumnTemplate}
                        />
                        <Column
                          field="amount"
                          header="Amount"
                          body={this.amountColumnTemplate}
                        />
                        <Column
                          field="charge"
                          header="Charge"
                          body={this.chargeColumnTemplate}
                        />

                        <Column
                          field="short_name"
                          header="Short Name"
                          body={this.shortNameColumnTemplate}
                        />
                        <Column
                          field="phone"
                          header="Destination"
                          body={this.phoneColumnTemplate}
                        />
                        <Column
                          field="country"
                          header="Country"
                          body={this.countryColumnTemplate}
                        />
                        <Column
                          field="status"
                          header="Status"
                          body={this.statusColumnTemplate}
                        />
                        <Column
                          field="date"
                          header="Date / Time"
                          body={this.dateColumnTemplate}
                        />
                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          style={{ width: "450px" }}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                {/*<Calendar inputStyle={filterInputBoxStyle} id="calendar" value={this.state.today} onChange={(e) => this.setState({ rangeValue: e.value, dateChange: 'Y' }, () => this.getFilteredData())} showIcon={true} style={{ width: '100%' }} />*/}
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredData()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                  style={{ width: "100%" }}
                />
                <label style={floatLabel}>DATE RANGE</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <span className="p-float-label">
                <Dropdown
                  style={filterInputBoxStyle}
                  id="dropdown1"
                  options={this.state.cities}
                  value={this.state.dropdownCity}
                  onChange={(event) =>
                    this.setState({ dropdownCity: event.value }, () =>
                      this.getFilteredDataCountry()
                    )
                  }
                  autoWidth={false}
                />
                <label style={floatLabel}>COUNTRY</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <span className="p-float-label">
                <Dropdown
                  style={filterInputBoxStyle}
                  id="dropdown1"
                  options={this.state.statusFilter}
                  value={this.state.statusFilterValue}
                  onChange={(event) =>
                    this.setState({ statusFilterValue: event.value }, () =>
                      this.getFilteredDataStatus()
                    )
                  }
                  autoWidth={false}
                />
                <label style={floatLabel}>STATUS</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <div className="p-inputgroup">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: "20%" }}
                    options={this.state.searchFilter}
                    value={this.state.dropdownSearchFilter}
                    onChange={(event) =>
                      this.setState({ dropdownSearchFilter: event.value })
                    }
                    autoWidth={false}
                  />
                  <label style={floatLabel}>Select Filter</label>
                </span>
              </div>
            </div>
            <div className="p-field p-col-12 p-md-8 p-md-offset-2">
              <div className="p-inputgroup">
                <InputText
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                  placeholder="Search Value"
                />
              </div>
            </div>
            <div
              className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
              style={{ textAlign: "center" }}
            >
              <Button
                label="Apply"
                onClick={() => this.getSearchedData()}
                icon="pi pi-search-plus"
                style={{ width: "50%" }}
              />
            </div>
          </div>
        </Dialog>
        {/*<Dialog visible={this.state.transactionDialog} style={{ width: '450px', overflowY: 'scroll' }} header="Transaction Detail" modal footer={transactionDialogFooter} onHide={this.hideTransactionDialog}>*/}
        <Dialog
          visible={this.state.transactionDialog}
          style={{ width: "450px", overflowY: "auto" }}
          header="Transaction Detail"
          modal
          onHide={this.hideTransactionDialog}
        >
          <span className={this.state.dialogStatus}>
            {this.state.dialogStatusText}
          </span>
          <div className="p-grid p-fluid transaction">
            <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
              <div style={cardStyle}>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Transaction ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogTransId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Ref ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogRefId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Institution ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialoginstitutionId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Short Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogShortName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Virtual Account ID</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogVaId}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Full Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogName}
                    </label>
                  </div>
                </div>
                <div
                  className="p-grid"
                  style={{ display: this.state.showAccountNumber }}
                >
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Account Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogAccountNum}
                    </label>
                  </div>
                </div>
                <div
                  className="p-grid"
                  style={{ display: this.state.showPhoneNumber }}
                >
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Phone Number</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogPhone}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Type</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.transType}
                    </label>
                  </div>
                </div>
                <div
                  className="p-grid"
                  style={{ display: this.state.showBankName }}
                >
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Bank Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>{this.state.bankName}</label>
                  </div>
                </div>
                <div
                  className="p-grid"
                  style={{ display: this.state.showBankName }}
                >
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Bank Short Name</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.bankShortName}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Amount</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogAmount}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Charge</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogCharge}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Country</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogCountry}
                    </label>
                  </div>
                </div>
                <div
                  className="p-grid"
                  style={{ display: this.state.showDestinationHouse }}
                >
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Destination House</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogDestinationHouse}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Exchange Rate</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogExchangeRate}
                    </label>
                  </div>
                </div>
                {/*<div className="p-grid">
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleLeft}>Recipient A/c Number</label>
                                    </div>
                                    <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                                        <label style={labelStyleRight}>{this.state.dialogReciAccountNumber}</label>
                                    </div>
                                </div>*/}
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Source Currency</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogSenderCurrency}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Destination Currency</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogReciCurrency}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Balance Before</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogBalanceBefore}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Balance After</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogBalanceAfter}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleLeft}>Description</label>
                  </div>
                  <div className="p-col-6 p-md-6 p-lg-6 p-xl-6 profileTop">
                    <label style={labelStyleRight}>
                      {this.state.dialogTransDesc}
                    </label>
                  </div>
                </div>
                <div className="p-grid">
                  <div
                    className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12 profileTop"
                    style={{ textAlign: "center" }}
                  >
                    <Button
                      label="Retry"
                      icon="pi pi-refresh"
                      className="p-button-help"
                      onClick={this.openConfirmationDialog}
                      style={{
                        marginTop: "1.5em",
                        width: "50%",
                        display: this.state.retryTransactionButton,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.confirmationDialog}
          style={{ width: "450px", overflowY: "auto" }}
          header=""
          modal
          onHide={this.hideConfirmationDialog}
        >
          <form onSubmit={this.requestRetryTransaction}>
            <div className="p-grid p-fluid" style={{ textAlign: "center" }}>
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid">
                  <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <img src={confirmationLogo} />
                  </div>
                  <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                    <span
                      style={{
                        color: "rgb(92, 74, 181)",
                        fontWeight: "700",
                        fontSize: "40px",
                      }}
                    >
                      Are you sure?
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                    style={{ paddingTop: "0" }}
                  >
                    <span style={{ color: "rgb(92, 74, 181)" }}>
                      {this.state.retryTransactionPromptMessage}
                    </span>
                  </div>
                  <div className="p-col-12 p-md-12 p-lg-8 p-xl-8 p-lg-offset-2 p-xl-offset-2">
                    <div className="p-grid">
                      <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          type="button"
                          label="Cancel"
                          className="p-button-outlined p-button-info"
                          onClick={this.hideConfirmationDialog}
                          style={{ marginTop: "1.5em" }}
                        />
                      </div>
                      <div className="p-col-6 p-md-6 p-lg-6 p-xl-6">
                        <Button
                          label="Confirm"
                          type="submit"
                          className="p-button-info"
                          style={{ marginTop: "1.5em" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(AdminTransactions);
