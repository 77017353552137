import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { ProgressSpinner } from "primereact/progressspinner";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { connect } from "react-redux";
import "../../config";
//import Cookies from 'universal-cookie';
import axios from "axios";
import queryString from "query-string";
const qs = require("query-string");
//const cookies = new Cookies();

class TopUpRequest extends Component {
  constructor() {
    super();
    document.title = "Top Up - Instant Credit";
    this.exportCSV = this.exportCSV.bind(this);
    this.state = {
      searchFilter: [{ label: "Short Name", value: "short_name" }],
      dialogRequestedBy: "",
      confirmationBtn: "",
      requestId: "",
      buttonID: "",
      dialogPhone: "",
      dialogCreatedAt: "",
      dateChange: "N",
      dialogInstitutionId: "",
      dialogNarration: "",
      dialogRate: "",
      dialogRequestType: "",
      dialogStatus: "",
      dialogStatusText: "",
      dialogDestinationHouse: "",
      dialogExchangeRate: "",
      dialogReciAccountNumber: "",
      dialogReciCurrency: "",
      dialogTransDesc: "",
      dialogVaId: "",
      searchValue: "",
      dataTableDummyValue: "",
      dialogVisible: false,
      forexDialog: false,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      productDialog: false,
      retryTransactionButton: "none",
      reversalTransactionButton: "none",
      closeTransactionButton: "none",
      submitted: false,
      dropdownCity: "All",
      dropdownSearchFilter: "",
      filterCountry: "",
      filterDate: "",
      searchValueText: "",
      dropdowncountries: "Filter Filter",
      status: null,
      date2: null,
      dataTableValue: [],
      dataTableValueNew: [],
      dataViewValue: [],
      selectedFile: null,
      selectedFiles: null,
      documents: [],
      documentsSelection: null,
      fullCalendarEvents: [],
      layout: "list",
      cities: [],
      rangeValue: [],
      showLoader: "none",
      contentOpacity: "1",
      actionColumnTemplateDataTip: "",
      detailDialogHeader: "",
      buttonClicked: false,
      isRequesting: false,
    };
    let today = new Date();
    let oneMonthBack = new Date();
    oneMonthBack.setMonth(oneMonthBack.getMonth() - 1);
    this.state.rangeValue[0] = oneMonthBack;
    this.state.rangeValue[1] = today;
    this.actionColumnTemplate = this.actionColumnTemplate.bind(this);
    this.viewAdjustmentsRequestDetails =
      this.viewAdjustmentsRequestDetails.bind(this);
    this.hideTransactionDialog = this.hideTransactionDialog.bind(this);
    this.requestedByColumnTemplate = this.requestedByColumnTemplate.bind(this);
    this.requestTypeColumnTemplate = this.requestTypeColumnTemplate.bind(this);
    this.institutionIdColumnTemplate =
      this.institutionIdColumnTemplate.bind(this);
    this.institutionNameColumnTemplate =
      this.institutionNameColumnTemplate.bind(this);
    this.narrationColumnTemplate = this.narrationColumnTemplate.bind(this);
    this.vaColumnTemplate = this.vaColumnTemplate.bind(this);
    this.dateColumnTemplate = this.dateColumnTemplate.bind(this);
    this.openNew = this.openNew.bind(this);
  }
  convertDate(dateString) {
    let finalDate;
    let fullDate = new Date(dateString);
    let year = fullDate.getFullYear();
    let month = Number(fullDate.getMonth() + 1);
    let date = fullDate.getDate();
    if (fullDate.getDate() < 10) {
      finalDate = year + "-" + month + "-0" + date;
    } else {
      finalDate = year + "-" + month + "-" + date;
    }
    return finalDate;
  }

  requestedByColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Amount</span>
        {rowData.requested_by}
      </React.Fragment>
    );
  }

  requestTypeColumnTemplate(rowData) {
    let reqType = rowData.request_type;
    let pTag = "p-tag p-tag-info";
    if ("0" == rowData.request_type) {
      reqType = "TopUp Request";
    } else {
      reqType = "Adjustment Request";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Phone</span>
        {reqType}
      </React.Fragment>
    );
  }

  institutionIdColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">InstitutionId</span>
        {rowData.institution_id}
      </React.Fragment>
    );
  }
  institutionNameColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Institution</span>
        {rowData.institution_name}
      </React.Fragment>
    );
  }

  narrationColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Narration</span>
        {rowData.narration}
      </React.Fragment>
    );
  }

  vaColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">VA Id</span>
        {rowData.va_id}
      </React.Fragment>
    );
  }

  dateColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Date / Time</span>
        {rowData.modified_at}
      </React.Fragment>
    );
  }

  statusColumnTemplate(rowData) {
    let ptag = rowData.status;
    let pTag = "";
    let status = "";
    if (ptag == "0") {
      status = "PENDING";
      pTag = "p-tag p-tag-warning";
    } else if (ptag == "1") {
      status = "APPROVED";
      pTag = "p-tag p-tag-success";
    } else {
      status = "DECLINED";
      pTag = "p-tag p-tag-danger";
    }
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        <span className={pTag}>{status}</span>
      </React.Fragment>
    );
  }

  actionColumnTemplate(rowData) {
    return (
      <React.Fragment>
        <span className="p-column-title">Action</span>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-info p-mr-2"
          onClick={() => this.viewAdjustmentsRequestDetails(rowData)}
          style={{ fontSize: "15px", padding: "7px" }}
          data-tip={this.state.actionColumnTemplateDataTip}
          data-for="toolTip1"
          data-place="top"
        />
        <ReactTooltip id="toolTip1" />
      </React.Fragment>
    );
  }

  requestResponse = (event) => {
    this.setState({ isRequesting: true });
    event.preventDefault();
    console.log(this.state.buttonID);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      entry_id: this.state.requestId,
      status: this.state.buttonID,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-set-status",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.hideTransactionDialog();
          this.toastSuccess(res.data.msg);
          this.getAdjustmentsData();
        } else {
          this.hideTransactionDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({ isRequesting: false });
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  viewAdjustmentsRequestDetails(rowData) {
    this.setState({
      requestId: rowData.request_id,
    });
    let reqType = "";
    let approvedBy = "";
    if ("0" == rowData.approved_by) {
      this.setState({
        dialogApprDeclinedText: "Approved/Declined By",
      });
      approvedBy = "N/A";
    } else {
      approvedBy = rowData.approved_by;
      this.setState({
        confirmationBtn: "none",
      });
      if ("1" == rowData.status) {
        this.setState({
          dialogApprDeclinedText: "Approved By",
        });
      } else {
        this.setState({
          dialogApprDeclinedText: "Declined By",
        });
      }
    }
    if ("0" == rowData.request_type) {
      reqType = "TopUp Request";
    } else {
      reqType = "Adjustment Request";
    }
    console.log(rowData);
    this.setState({
      dialogCreatedAt: rowData.modified_at,
      dialogInstitutionId: rowData.institution_id,
      dialogNarration: rowData.narration,
      dialogRequestType: reqType,
      dialogRequestedBy: rowData.requested_by,
      dialogAmount: rowData.amount,
      dialogApprovedBy: approvedBy,
      forexDialog: true,
    });
  }

  hideTransactionDialog() {
    if ("101" == this.props.userDetails.type) {
      this.setState({
        confirmationBtn: "initial",
        actionColumnTemplateDataTip: "Take Action",
        detailDialogHeader: "Confirmation",
      });
    } else {
      this.setState({
        confirmationBtn: "none",
        actionColumnTemplateDataTip: "View Details",
        detailDialogHeader: "Top Up Details",
      });
    }
    this.setState({ forexDialog: false });
  }

  handleSearchValueChange = (e) => {
    this.setState({
      searchValue: e.target.value,
    });
  };

  componentDidMount() {
    if ("101" == this.props.userDetails.type) {
      this.setState({
        confirmationBtn: "initial",
        actionColumnTemplateDataTip: "Take Action",
        detailDialogHeader: "Confirmation",
      });
    } else {
      this.setState({
        confirmationBtn: "none",
        actionColumnTemplateDataTip: "View Details",
        detailDialogHeader: "Top Up  Details",
      });
    }
    this.setState({
      cities: this.props.userDetails.allCountries,
    });
    this.updateDimensions();
    this.getAdjustmentsData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }

  hideDialog = () => {
    this.setState({
      submitted: false,
      productDialog: false,
    });
  };

  exportCSV() {
    this.dt.exportCSV();
  }

  openNew = (data) => {
    this.setState({
      submitted: false,
      productDialog: true,
    });
  };

  onSortChange(event) {
    let value = event.value;

    if (value.indexOf("!") === 0)
      this.setState({
        sortOrder: -1,
        sortField: value.substring(1, value.length),
        sortKey: value,
      });
    else this.setState({ sortOrder: 1, sortField: value, sortKey: value });
  }

  getFilteredDataDate = async () => {
    if (null != this.state.rangeValue[1]) {
      this.state.dialogVisible = false;
      this.filterDataByDate();
    }
  };

  filterDataByDate = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      type: 0,
      start_date: startDate,
      end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getSearchedData = async () => {
    if ("" == this.state.dropdownSearchFilter || "" == this.state.searchValue)
      return false;
    this.setState({
      dialogVisible: false,
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      type: 0,
      search_type: this.state.dropdownSearchFilter,
      search_val: this.state.searchValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  getAdjustmentsData = async () => {
    let startDate = this.convertDate(this.state.rangeValue[0]);
    let endDate = this.convertDate(this.state.rangeValue[1]);
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      type: 0,
      //start_date: startDate,
      //end_date: endDate,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log(res.data);
          this.createDataTable(res.data.data);
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createDataTable(data) {
    this.state.dataTableValueNew = [];
    this.state.dataTableValue = [];
    this.state.dataTableValueNew = data;
    this.state.dataTableValueNew.forEach((d) => {
      this.state.dataTableValue.push({
        request_id: d["request_id"],
        request_type: d["request_type"],
        requested_by: d["requested_by_name"],
        institution_id: d["institution_id"]["user_id"],
        institution_name: d["institution_id"]["short_name"],
        va_id: d["va_id"],
        narration: d["narration"],
        request_id: d["id"],
        modified_at: d["modified_at"],
        amount: d["amount"],
        approved_by: d["approved_by"],
        approved_by_name: d["approved_by_name"],
        status: d["status"],
      });
    });
    this.setState({ dataTableDummyValue: this.state.dataTableValue });
  }

  render() {
    const { isRequesting } = this.state;

    const dialogFooter = (
      <Button
        label="Apply"
        onClick={() => this.getSearchedData()}
        icon="pi pi-search-plus"
      />
    );
    const header = (
      <div style={{ textAlign: "right" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          label="Export"
          onClick={this.exportCSV}
          style={{ width: "12%" }}
        ></Button>
      </div>
    );
    const forexDialogFooter = (
      <React.Fragment>
        {/*<Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.HideTransactionDialog} />
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct} />*/}
        <Button
          label="Retry"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.retryTransactionButton,
          }}
        />
        <Button
          label="Reverse Transaction"
          icon="pi pi-refresh"
          className="p-button-help"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.reversalTransactionButton,
          }}
        />
        <Button
          label="Close"
          className="p-button-success"
          onClick={this.hideTransactionDialog}
          style={{
            marginTop: "1.5em",
            display: this.state.closeTransactionButton,
          }}
        />
      </React.Fragment>
    );
    const productDialogFooter = (
      <React.Fragment>
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="p-button-text"
          onClick={this.hideDialog}
        />
        <Button
          label="Save"
          icon="pi pi-check"
          className="p-button-text"
          onClick={this.saveProduct}
        />
      </React.Fragment>
    );
    const inputBoxStyle = {
      borderRadius: "5px",
      border: "none",
      paddingLeft: "12px",
      backgroundColor: "#f7f7f7",
    };
    const filterInputBoxStyle = { borderRadius: "4px" };
    const pgridCustomStyle = { marginRight: "0", marginLeft: "0" };
    const cardStyle = { borderRadius: "40px" };
    const h3Style = {
      color: "rgb(92, 74, 181)",
      fontWeight: "500",
      fontSize: "15px",
      marginBottom: "1em",
    };
    const labelStyleLeft = { fontSize: "14px" };
    const labelStyleLeftFooter = { fontSize: "14px", color: "blue" };
    const labelStyleRight = {
      fontSize: "14px",
      float: "left",
      fontWeight: "500",
    };
    const labelStyleHeader = {
      fontSize: "14px",
      fontWeight: "600",
      color: "rgb(92, 74, 181)",
    };
    const labelStyle = {
      paddingLeft: "10px",
      fontSize: "12px",
      color: "#999",
      fontWeight: "500",
    };
    const detailsStyle = {
      fontSize: "14px",
      paddingTop: "0.4em",
      color: "rgb(92, 74, 181)",
      float: "right",
    };
    const floatLabel = {
      fontWeight: "500",
      color: "rgb(153, 153, 153)",
      fontSize: "12px",
    };

    return (
      <div
        className="layout-content"
        style={{ opacity: this.state.contentOpacity }}
      >
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <ProgressSpinner
          style={{
            display: this.state.showLoader,
            width: "40px",
            height: "40px",
            position: "fixed",
            top: "50%",
            left: "50%",
            bottom: "50%",
            right: "50%",
            zIndex: "1111111111",
          }}
          strokeWidth="3"
          animationDuration=".5s"
        />
        <div>
          <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
            <div className="p-col-12 p-md-12 p-xl-12">
              <div className="p-grid">
                <div
                  className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                  style={{ textAlign: "center" }}
                >
                  <div>
                    <span className="p-tag custom-heading">
                      Top Up Requests
                    </span>
                  </div>
                </div>
              </div>

              <div className="p-grid transaction">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "2px",
                      display: this.state.showFiltersMob,
                    }}
                  >
                    <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                      <Button
                        icon="pi pi-sliders-h"
                        label="Filters"
                        className="p-button-info"
                        style={{
                          backgroundColor: "#f0f0f0",
                          border: "1px solid #f0f0f0",
                          color: "#999",
                          float: "left",
                          width: "75%",
                        }}
                        onClick={() => this.setState({ dialogVisible: true })}
                      />
                    </div>
                  </div>
                  <div
                    className="p-grid p-fluid"
                    style={{
                      marginTop: "24px",
                      display: this.state.showFiltersWeb,
                    }}
                  >
                    <div className="p-col-6 p-md-3 p-lg-3 p-xl-4">
                      <span className="p-float-label">
                        <Calendar
                          id="range"
                          value={this.state.rangeValue}
                          onChange={(e) =>
                            this.setState(
                              { rangeValue: e.value, dateChange: "Y" },
                              () => this.getFilteredDataDate()
                            )
                          }
                          selectionMode="range"
                          readOnlyInput
                          showIcon={true}
                        />
                        <label htmlFor="calendar">DATE RANGE</label>
                      </span>
                    </div>
                    <div className="p-col-6 p-md-4 p-lg-3 p-xl-4 p-lg-offset-6 p-xl-offset-4">
                      <div className="p-grid">
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingRight: "0.3px" }}
                        >
                          <div className="p-inputgroup">
                            <span className="p-float-label">
                              <Dropdown
                                style={{ width: "20%" }}
                                options={this.state.searchFilter}
                                value={this.state.dropdownSearchFilter}
                                onChange={(event) =>
                                  this.setState({
                                    dropdownSearchFilter: event.value,
                                  })
                                }
                                autoWidth={false}
                              />
                              <label style={floatLabel}>Select Filter</label>
                            </span>
                          </div>
                        </div>
                        <div
                          className="p-col-6 p-xl-6"
                          style={{ paddingLeft: "0.4px" }}
                        >
                          <div className="p-inputgroup">
                            <InputText
                              value={this.state.searchValue}
                              onChange={(event) =>
                                this.setState({
                                  searchValue: event.target.value,
                                })
                              }
                              placeholder="Search Value"
                            />
                            <Button
                              onClick={() => this.getSearchedData()}
                              icon="pi pi-search"
                              className="p-button-info"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="p-grid ">
                <div
                  className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                  style={{ padding: "0" }}
                >
                  <div className="datatable-responsive-demo">
                    <div className="card">
                      <DataTable
                        className="p-datatable-responsive-demo"
                        resizableColumns
                        columnResizeMode="expand"
                        value={this.state.dataTableValue}
                        paginator={true}
                        rows={10}
                        ref={(el) => {
                          this.dt = el;
                        }}
                      >
                        <Column
                          field="request_id"
                          style={{ display: "none" }}
                        />
                        <Column field="amount" style={{ display: "none" }} />
                        <Column
                          field="approved_by"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="approved_by_name"
                          style={{ display: "none" }}
                        />
                        <Column
                          field="institution_id"
                          header="Institution"
                          body={this.institutionIdColumnTemplate}
                          style={{ display: "none" }}
                        />
                        <Column
                          field="request_type"
                          header="Request Type"
                          body={this.requestTypeColumnTemplate}
                          style={{ display: "none" }}
                        />
                        <Column
                          field="requested_by"
                          header="Requested By"
                          body={this.requestedByColumnTemplate}
                        />
                        <Column
                          field="institution_name"
                          header="Institution"
                          body={this.institutionNameColumnTemplate}
                        />
                        <Column
                          field="va_id"
                          header="VA Id"
                          body={this.vaColumnTemplate}
                        />
                        <Column
                          field="status"
                          header="Status"
                          body={this.statusColumnTemplate}
                        />
                        <Column
                          field="modified_at"
                          header="Date"
                          body={this.dateColumnTemplate}
                        />
                        <Column
                          header="Action"
                          body={this.actionColumnTemplate}
                        ></Column>
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          visible={this.state.forexDialog}
          style={{ width: "450px", overflowY: "scroll" }}
          header={this.state.detailDialogHeader}
          modal
          onHide={this.hideTransactionDialog}
        >
          <form onSubmit={this.requestResponse}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div style={cardStyle}>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Request Type</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogRequestType}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Institution ID</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogInstitutionId}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Amount</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogAmount}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Date</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogCreatedAt}
                      </label>
                    </div>
                  </div>
                  <div className="p-grid">
                    <div className="p-col-4 p-md-4 p-lg-4 p-xl-4 profileTop">
                      <label style={labelStyleLeft}>Narration</label>
                    </div>
                    <div className="p-col-8 p-md-8 p-lg-8 p-xl-8 profileTop">
                      <label style={labelStyleRight}>
                        {this.state.dialogNarration}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="p-grid p-fluid"
              id={this.state.requestId}
              style={{ display: this.state.confirmationBtn }}
            >
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid">
                  <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4 p-offset-2 p-sm-offset-2 p-md-offset-2 p-lg-offset-2 p-xl-offset-2">
                    <Button
                      label="Decline"
                      type="submit"
                      onClick={(e) => this.setState({ buttonID: "2" })}
                      className="p-button-outlined p-button-info"
                      disabled={isRequesting}
                      style={{ marginTop: "1.5em" }}
                    />
                  </div>
                  <div className="p-col-4 p-sm-4 p-md-4 p-lg-4 p-xl-4">
                    <Button
                      label="Accept"
                      id="1"
                      onClick={(e) => this.setState({ buttonID: "1" })}
                      className="p-button-info"
                      value="decline"
                      type="submit"
                      disabled={isRequesting}
                      style={{ marginTop: "1.5em" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  id="range"
                  value={this.state.rangeValue}
                  onChange={(e) =>
                    this.setState(
                      { rangeValue: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  selectionMode="range"
                  readOnlyInput
                  showIcon={true}
                  style={{ width: "100%" }}
                />
                <label htmlFor="calendar">DATE RANGE</label>
              </span>
            </div>
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1em" }}
            >
              <div className="p-inputgroup">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: "20%" }}
                    options={this.state.searchFilter}
                    value={this.state.dropdownSearchFilter}
                    onChange={(event) =>
                      this.setState({ dropdownSearchFilter: event.value })
                    }
                    autoWidth={false}
                  />
                  <label style={floatLabel}>Select Filter</label>
                </span>
              </div>
            </div>
            <div className="p-field p-col-12 p-md-8 p-md-offset-2">
              <div className="p-inputgroup">
                <InputText
                  value={this.state.searchValue}
                  onChange={this.handleSearchValueChange}
                  placeholder="Search Value"
                />
              </div>
            </div>
            <div
              className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12"
              style={{ textAlign: "center" }}
            >
              <Button
                label="Apply"
                onClick={() => this.getSearchedData()}
                icon="pi pi-search-plus"
                style={{ width: "50%" }}
              />
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
  };
};

export default connect(mapStateToProps, null)(TopUpRequest);
