import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { VAAction } from "../../store/actions/VAAction";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../config";
const qs = require("query-string");

const checkHistory = "assets/layout/images/refresh.png";
const topUp = "assets/layout/images/topup.png";
const deduct = "assets/layout/images/topdown.png";
const cardStyle = {
  /*{ borderRadius: '20px' };*/
};
const detailsStyle = {
  fontSize: "8px",
  paddingTop: "0.8em",
  color: "rgb(189,220,254)",
};
const detailsStyle1 = {
  fontSize: "14px",
  paddingTop: "0.4em",
  color: "rgb(92, 74, 181)",
  float: "right",
};
const buttonDetailsStyle = { fontSize: "10px" };
const topupButtonStyle = {
  padding: "5px",
  background: "rgb(92, 74, 181)",
  border: "0",
  color: "#fff",
  paddingRight: "10px",
  float: "right",
  width: "80%",
};
const cardHeadingStyle = {
  color: "#5c4ab5",
  fontWeight: "600",
  position: "absolute",
};

const filterInputBoxStyle = { borderRadius: "4px" };
const labelStyle = {
  paddingLeft: "10px",
  fontSize: "12px",
  color: "#999",
  fontWeight: "500",
};
const amountBorder = {
  borderBottomLeftRadius: "10px",
  borderTopLeftRadius: "10px",
};

class AdminVirtualAccounts extends Component {
  constructor() {
    super();
    document.title = "Virtual Accounts - Instant Credit";
    let today1 = new Date();
    this.state = {
      showBox: "none",
      country: "",
      userId: "",
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCountry: "",
      currencyValue: "",
      accountType: [
        { label: "All", value: "2" },
        { label: "Bank", value: "0" },
        { label: "Wallet", value: "1" },
      ],
      today: today1,
      showFiltersWeb: "none",
      showFiltersMob: "none",
      data: [],
      items: [],
      topUpDialog: false,
      deducationDialog: false,
      institutionName: "",
      amount: "",
      vaId: "",
      purpose: "",
      accountName: "",
      topUpAmountError: "",
      topUpPurposeError: "",
      deductAmountError: "",
      deductPurposeError: "",
      deductAmount: "",
      deductPurpose: "",
      balanceAmountTooltip: "",
      isRequesting: false,
      buttonClicked: false,
    };
  }

  viewVirtualAccountDialog = () => {
    this.setState({
      viewVirtualAccountDialog: true,
    });
  };
  hideVirtualAccountDialog = () => {
    this.state.viewVirtualAccountDialog = false;
    this.setState({
      vaName: "",
      dropdownAccountType: "",
      vaThreshold: "",
      dropdownCountry: "",
    });
  };

  getFilteredDataDate = async () => {
    this.state.dialogVisible = false;
  };

  getCountryList = async () => {
    this.setState({
      contentOpacity: 0.5,
      showLoader: "block",
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "country-list",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          const countryData = res.data.data;
          // Initialize an empty object to store country-currency mappings
          const countryCurrencyMap = {};

          // Populate the countryCurrencyMap object
          countryData.forEach((country) => {
            countryCurrencyMap[country.name] = country.currency;
          });

          // Extract country names and currencies separately
          const names = countryData.map((country) => country.name);
          const currencies = countryData.map((country) => country.currency);

          this.setState({
            contentOpacity: 1,
            showLoader: "none",
            country: names,
            currencyValue: currencies,
            countryCurrencyMap: countryCurrencyMap,
          });
        } else {
          this.setState({
            contentOpacity: 1,
            showLoader: "none",
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  createVirtualAccount = (event) => {
    event.preventDefault();
    console.log(this.state.userId);
    if ("" == this.state.vaName) {
      this.setState({
        vaNameError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaNameError: "",
      });
    }
    if ("" == this.state.dropdownAccountType) {
      this.setState({
        accountTypeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        accountTypeError: "",
      });
    }
    if ("" == this.state.vaThreshold) {
      this.setState({
        vaThresholdError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        vaThresholdError: "",
      });
    }
    if ("" == this.state.dropdownCountry) {
      this.setState({
        currencyError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        currencyError: "",
      });
    }
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.state.userId,
      account_name: this.state.vaName,
      account_type: this.state.dropdownAccountType,
      threshold_amount: this.state.vaThreshold,
      country_name: this.state.dropdownCountry,
      currency: this.state.currencyValue,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "create-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.getVirtualAccountData();
          this.hideVirtualAccountDialog();
          this.toastSuccess(res.data.msg);
        } else {
          this.hideVirtualAccountDialog();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  componentDidMount() {
    this.setState({
      userId: this.props.userId,
    });
    console.log(this.props);
    this.getVirtualAccountData();
    this.getCountryList();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions() {
    if (window.innerWidth < 1025) {
      this.setState({ showFiltersWeb: "none", showFiltersMob: "flex" });
    } else {
      let update_width = window.innerWidth - 100;
      this.setState({ showFiltersWeb: "flex", showFiltersMob: "none" });
    }
  }
  deductVirtualAccount = (event) => {
    this.setState({ isRequesting: true });
    event.preventDefault();
    console.log(this.state.vaId);
    console.log(this.state.deductAmount);
    console.log(this.state.deductPurpose);
    if ("" == this.state.deductAmount) {
      this.setState({
        deductAmountError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        deductAmountError: "",
      });
    }
    if ("" == this.state.deductPurpose) {
      this.setState({
        deductPurposeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        deductPurposeError: "",
      });
    }
    this.setState({
      buttonClicked: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      request_type: 1,
      va_id: this.state.vaId,
      amount: this.state.deductAmount,
      description: this.state.deductPurpose,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          console.log("DEDUCT TRUE");
          this.hideDeductionDialog();
          this.getVirtualAccountData();
          this.toastSuccess(res.data.msg);
        } else {
          console.log("DEDUCT FALSE");
          this.hideDeductionDialog();
          this.getVirtualAccountData();
          this.toastError(res.data.msg);
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      })
      .finally(() => {
        this.setState({ isRequesting: false });
      });
  };

  topUpVirtualAccount = (event) => {
    console.log("About to process top up.");
    this.setState({ isRequesting: true });
    event.preventDefault();
    if ("" == this.state.amount) {
      this.setState({
        topUpAmountError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        topUpAmountError: "",
      });
    }
    if ("" == this.state.purpose) {
      this.setState({
        topUpPurposeError: "1.5px solid red",
      });
      return false;
    } else {
      this.setState({
        topUpPurposeError: "",
      });
    }
    this.setState({
      buttonClicked: true,
    });
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      request_type: 0,
      va_id: this.state.vaId,
      amount: this.state.amount,
      description: this.state.purpose,
      token: this.props.userDetails._id,
    };

    axios
      .post(
        global.config.main.baseUrl + "va-adjustment-add",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          this.hideTopUpDialog();
          this.getVirtualAccountData();
          this.toastSuccess(res.data.msg);
          this.setState({
            buttonClicked: false,
          });
        } else {
          this.hideTopUpDialog();
          this.getVirtualAccountData();
          this.toastError(res.data.msg);
          this.setState({
            buttonClicked: false,
          });
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
            buttonClicked: false,
          });
        }
      });
  };
  toastSuccess(msg) {
    this.toastBR.show({
      severity: "success",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  toastError(msg) {
    this.toastBR.show({
      severity: "error",
      summary: "",
      detail: msg,
      life: 5000,
    });
  }
  navigate2VAHistory = (id) => {
    let payload = { vaid: id };
    this.props.vaAction(payload).then((result) => {
      if (result.success) {
        this.props.history.push("/va-history");
      } else {
        alert("Failed");
      }
    });
  };
  buttonOnclick = (e) => {
    e.preventDefault();
  };

  openTopUpDialog = (vaId, acName) => {
    console.log(vaId);
    this.setState({
      vaId: vaId,
      accountName: acName,
    });
    this.state.topUpDialog = true;
    this.setState({
      buttonClicked: false,
    });
  };
  hideTopUpDialog = () => {
    this.setState({
      purpose: "",
      amount: "",
      topUpDialog: false,
    });
  };
  openDeductDialog = (vaId, acName) => {
    this.setState({
      vaId: vaId,
      accountName: acName,
    });
    this.state.deducationDialog = true;
  };
  hideDeductionDialog = () => {
    this.state.deducationDialog = false;
    this.setState({
      deductAmount: "",
      deductPurpose: "",
      deducationDialog: false,
    });
  };

  getVirtualAccountData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      userId: this.props.userId,
      token: this.props.userDetails._id,
    };
    axios
      .post(
        global.config.main.baseUrl + "list-user-va",
        qs.stringify(data),
        config
      )
      .then((res) => {
        if (res.data.status === 200) {
          if ("" == res.data.data) {
            this.toastSuccess("No Virtual Account created");
            this.setState({
              showBox: "none",
            });
          } else {
            this.setState({
              showBox: "flex",
            });
          }

          this.state.items = [];
          this.state.data = [];
          this.state.institutionName = res.data.name;
          this.state.data = res.data.data;
          this.state.data.forEach((d) => {
            let str = d["balance"];
            this.setState({
              balanceAmountTooltip: d["balance"],
            });

            let tipAmount =
              d["currency"] +
              " " +
              this.state.balanceAmountTooltip.toLocaleString();
            this.state.items.push(
              <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                  <div className="highlight-details ">
                    <span style={cardHeadingStyle}>{d["account_name"]} </span>
                    <span
                      className="count"
                      data-tip={tipAmount}
                      data-for="toolTip1"
                      data-place="top"
                      style={{
                        paddingTop: "1em",
                        wordWrap: "break-word",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      {d["currency"]}&nbsp;{str.toLocaleString()}
                    </span>
                    <ReactTooltip id="toolTip1" />
                    <div className="p-grid cardFooter">
                      <div className="p-col-7 p-md-8 p-lg- p-xl-6"></div>
                      <div className="p-col-5 p-md-4 p-lg-4 p-xl-6">
                        <button
                          style={topupButtonStyle}
                          onClick={() =>
                            this.openTopUpDialog(d["va_id"], d["account_name"])
                          }
                        >
                          <span>
                            <img
                              src={topUp}
                              alt="my image"
                              style={{ width: "10px" }}
                            />
                          </span>
                          <span style={{ fontSize: "12px" }}>Top up</span>
                        </button>
                      </div>
                    </div>
                    <div className="p-grid">
                      <div className="p-col-7 p-md-8 p-lg- p-xl-6">
                        <span
                          className="detail span-custom-success"
                          style={detailsStyle}
                        >
                          <img
                            src={checkHistory}
                            style={{ height: "15px", width: "15px" }}
                          />
                          <Link
                            to="/va-history"
                            onClick={() => this.navigate2VAHistory(d["va_id"])}
                            style={{ color: "rgb(189, 220, 254)" }}
                          >
                            Check History
                          </Link>
                        </span>
                      </div>
                      <div className="p-col-5 p-md-4 p-lg-4 p-xl-6">
                        <button
                          style={topupButtonStyle}
                          onClick={() =>
                            this.openDeductDialog(d["va_id"], d["account_name"])
                          }
                        >
                          <span>
                            <img
                              src={deduct}
                              alt="my image"
                              style={{ width: "10px" }}
                            />
                          </span>
                          <span style={{ fontSize: "12px" }}>Deduct</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
          this.setState({ items2: this.state.item });
        } else {
        }
      })
      .catch((error) => {
        if (!error.response) {
          this.setState({
            msg: "A network error occurred",
          });
        }
      });
  };

  render() {
    const addForex = "assets/layout/images/forex_add.png";
    const vaNameborder = { border: this.state.vaNameError };

    return (
      <div>
        <Toast ref={(el) => (this.toastBR = el)} position="top-right" />
        <div className="p-grid p-fluid" style={{ marginTop: "20px" }}>
          <div className="p-col-12 p-md-12 p-xl-12">
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-4 p-xl-4 p-md-offset-4 p-xl-offset-4"
                style={{ textAlign: "center" }}
              >
                <span className="p-tag custom-heading">Virtual Accounts</span>
              </div>
            </div>
            <div className="p-grid transaction">
              <div
                className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1 card"
                style={{ paddingBottom: "10px", paddingTop: "20px" }}
              >
                <div
                  className="p-grid p-fluid"
                  style={{ display: this.state.showFiltersMob }}
                >
                  <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <Link to="/institution-list">
                      <span
                        className="p-tag custom-heading"
                        style={{ fontSize: "15px" }}
                      >
                        {this.state.institutionName}
                      </span>
                    </Link>
                  </div>
                  <div className="p-field p-col-6 p-md-6 p-lg-6 p-xl-6">
                    <span
                      className="detail span-custom-success"
                      style={detailsStyle1}
                    >
                      Add VA&nbsp;
                      <img
                        src={addForex}
                        style={{ height: "25px", width: "25px" }}
                        onClick={() => this.viewVirtualAccountDialog()}
                      />
                    </span>
                  </div>
                </div>
                <div
                  className="p-grid p-fluid"
                  style={{ display: this.state.showFiltersWeb }}
                >
                  <div className="p-field p-col-6 p-md-3 p-xl-3">
                    <span className="p-float-label">
                      <Link to="/institution-list">
                        <span
                          className="p-tag custom-heading"
                          style={{ fontSize: "15px" }}
                        >
                          {this.state.institutionName}
                        </span>
                      </Link>
                    </span>
                  </div>
                  <div className="p-field p-col-6 p-md-3 p-xl-3"></div>
                  <div className="p-col-12 p-md-4 p-xl-4"></div>
                  <div className="p-col-4 p-md-2 p-xl-2">
                    <span
                      className="detail span-custom-success"
                      style={detailsStyle1}
                    >
                      Add VA &nbsp;
                      <img
                        src={addForex}
                        style={{ height: "25px", width: "25px" }}
                        onClick={() => this.viewVirtualAccountDialog()}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="p-col-12 p-md-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
              style={{ padding: "0px" }}
            >
              <div
                className="p-grid p-fluid card"
                style={{ display: this.state.showBox }}
              >
                {this.state.items}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          header="Filters"
          visible={this.state.dialogVisible}
          onHide={() => this.setState({ dialogVisible: false })}
        >
          <div className="p-grid">
            <div
              className="p-field p-col-12 p-md-8 p-md-offset-2"
              style={{ marginTop: "1.5em" }}
            >
              <span className="p-float-label">
                <Calendar
                  inputStyle={filterInputBoxStyle}
                  id="calendar"
                  value={this.state.today}
                  onChange={(e) =>
                    this.setState(
                      { filterDate: e.value, dateChange: "Y" },
                      () => this.getFilteredDataDate()
                    )
                  }
                  showIcon={true}
                  style={{ width: "100%" }}
                />
                <label htmlFor="calendar">DATE</label>
              </span>
            </div>
          </div>
        </Dialog>
        <Dialog
          visible={this.state.topUpDialog}
          style={{ width: "450px" }}
          header="Top Up Account"
          modal
          onHide={this.hideTopUpDialog}
        >
          <form onSubmit={this.topUpVirtualAccount}>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        value={this.state.accountName}
                        style={{ color: "#8c8c8c", backgroundColor: "#d4d4d4" }}
                        readOnly
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        {/*<Button onClick={this.buttonOnclick} icon="pi pi-dollar"/>
                                            	<label htmlFor="dropdown1" style={labelStyle}>Amount</label>*/}
                        <div className="p-inputgroup">
                          <InputText
                            onChange={(e) =>
                              this.setState({ amount: e.target.value })
                            }
                            type="text"
                          />
                          <span
                            className="p-inputgroup-addon"
                            style={{
                              border: "0",
                              backgroundColor: "#5c4ab5",
                              color: "#fff",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            ₵
                          </span>
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Amount
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputTextarea
                        id="purpose"
                        rows={3}
                        cols={30}
                        autoResize={true}
                        value={this.state.purpose}
                        onChange={(e) =>
                          this.setState({ purpose: e.target.value })
                        }
                        style={{ border: this.state.topUpPurposeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Description
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Request Top Up"
                  className="p-button-info"
                  type="submit"
                  disabled={this.state.buttonClicked}
                  style={{ width: "60%", marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.deducationDialog}
          style={{ width: "450px" }}
          header="Deduct Account"
          modal
          onHide={this.hideDeductionDialog}
        >
          <form onSubmit={this.deductVirtualAccount}>
            <div className="p-grid p-fluid transaction">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        value={this.state.accountName}
                        readOnly
                        style={{ color: "#8c8c8c", backgroundColor: "#d4d4d4" }}
                      />
                      <label style={labelStyle}>Account Name</label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        {/*<InputText type="number" value={this.state.deductAmount} onChange={(e) => this.setState({ deductAmount: e.target.value })} style={{border:this.state.deductAmountError,borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} min="1" />
												<Button onClick={this.buttonOnclick} icon="pi pi-dollar"/>
                                            	<label htmlFor="dropdown1" style={labelStyle}>Amount</label>*/}
                        <div className="p-inputgroup">
                          <InputText
                            type="text"
                            value={this.state.deductAmount}
                            onChange={(e) =>
                              this.setState({ deductAmount: e.target.value })
                            }
                            style={{
                              border: this.state.deductAmountError,
                              borderTopLeftRadius: "10px",
                              borderBottomLeftRadius: "10px",
                            }}
                            min="1.0"
                          />
                          <span
                            className="p-inputgroup-addon"
                            style={{
                              border: "0",
                              backgroundColor: "#5c4ab5",
                              color: "#fff",
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            ₵
                          </span>
                          <label htmlFor="dropdown1" style={labelStyle}>
                            Amount
                          </label>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputTextarea
                        id="purpose"
                        rows={3}
                        cols={30}
                        autoResize={true}
                        value={this.state.deductPurpose}
                        onChange={(e) =>
                          this.setState({ deductPurpose: e.target.value })
                        }
                        style={{ border: this.state.deductPurposeError }}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Description
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid" style={{ textAlign: "center" }}>
              <div className="p-col-12 p-md-12 p-lg-12 p-xl-12">
                <Button
                  label="Request Deduction"
                  className="p-button-info"
                  disabled={this.state.buttonClicked}
                  type="submit"
                  style={{ width: "62%", marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
        <Dialog
          visible={this.state.viewVirtualAccountDialog}
          style={{ width: "450px" }}
          header="Create Virtual Account"
          modal
          onHide={this.hideVirtualAccountDialog}
        >
          <form onSubmit={this.createVirtualAccount}>
            <div className="p-grid p-fluid">
              <div className="p-col-12 p-sm-12 p-md-12 p-lg-12 p-xl-12">
                <div className="p-grid p-fluid">
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        name="dialogName"
                        onChange={(event) =>
                          this.setState({ vaName: event.target.value })
                        }
                        value={this.state.vaName}
                        style={vaNameborder}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Virtual Account Name
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-10 p-lg-10 p-xl-10 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{
                      marginTop: "10px",
                      border: this.state.accountTypeError,
                    }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.accountType}
                        value={this.state.dropdownAccountType}
                        onChange={(event) =>
                          this.setState({
                            dropdownAccountType: event.target.value,
                          })
                        }
                        autoWidth={false}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Select Account Type
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-5 p-lg-5 p-xl-5 p-md-offset-1 p-lg-offset-1 p-xl-offset-1"
                    style={{ marginTop: "20px" }}
                  >
                    <span className="p-float-label">
                      <InputText
                        type="number"
                        name="dialogName"
                        onChange={(event) =>
                          this.setState({ vaThreshold: event.target.value })
                        }
                        value={this.state.vaThreshold}
                        style={{ border: this.state.vaThresholdError }}
                        min="1"
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Threshold Amount
                      </label>
                    </span>
                  </div>
                  <div
                    className="p-col-12 p-md-5 p-lg-5 p-xl-5"
                    style={{
                      marginTop: "20px",
                      border: this.state.currencyError,
                    }}
                  >
                    <span className="p-float-label">
                      <Dropdown
                        style={filterInputBoxStyle}
                        id="dropdown1"
                        options={this.state.country}
                        value={this.state.dropdownCountry}
                        onChange={(event) => {
                          const selectedCountry = event.target.value;

                          // Retrieve the currency value from the countryCurrencyMap
                          const selectedCurrency =
                            this.state.countryCurrencyMap[selectedCountry];

                          if (selectedCurrency) {
                            // If currency is found, update the state with the selected country and its currency
                            this.setState({
                              dropdownCountry: selectedCountry,
                              currencyValue: selectedCurrency,
                            });
                          } else {
                            console.log("Selected country not found in data.");
                            // Reset to default values if country not found
                            this.setState({
                              dropdownCountry: "",
                              currencyValue: "",
                            });
                          }
                        }}
                        autoWidth={false}
                      />
                      <label htmlFor="dropdown1" style={labelStyle}>
                        Select Country
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div
                className="p-col-12 p-md-12 p-lg-12 p-xl-12"
                style={{ textAlign: "center" }}
              >
                <Button
                  label="Confirm"
                  className="p-button-info"
                  type="submit"
                  style={{ width: "50%", marginTop: "1.5em" }}
                />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.login.userDetails,
    userId: state.login.vaData.userid,
  };
};

const mapDispatchToProps = {
  vaAction: VAAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminVirtualAccounts);
